import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Button, Menu, FormControl, Box, Select,MenuItem, InputLabel, RadioGroup, FormControlLabel, Radio, TextField, styled, SelectChangeEvent, Typography } from '@mui/material'
import { NestedMenuItem } from './NestedMenuItem'
import { Add, MoreVert } from "@mui/icons-material"
import { UseMutationResult, UseQueryResult } from 'react-query'
import { objectEntries } from '../../utils/objects'
import { grey } from '@mui/material/colors'


const StyledMenuItem = styled(Box)({
  paddingLeft: '4px',
  paddingRight: '4px',
  paddingTop: '1px',
  paddingBottom: '1px',
  display: 'flex',
  backgroundColor: '#d7d7d7',
  justifyContent: 'space-between',
})

const StyledEditMenu = styled(Box)({
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingTop: '1px',
  minWidth: '280px',
  paddingBottom: '1px',
  display: 'flex',
  flexDirection: 'column',
  gap: "10px",

})

export const colourValues: Record<string,string> = {
  "Blue": '#d3e5ef',
  "Red": '#ffe2dd',
  "Yellow": '#fdecc8',
  "Green": '#dbeddb',
}

type StatusBody<TEnumValue extends string> = {
  label: string,
  progress: TEnumValue,
  color: string,
}

export type Status<TEnumValue extends string> = StatusBody<TEnumValue> & {
  id: string,
}

type PersistedStatus<TEnumValue extends string> = StatusBody<TEnumValue> & {
  statusId: string,
}

export type StatusWidgetProps<TEnum extends Record<string, string>, TEnumValue extends TEnum[keyof TEnum]> = {
  parentId: string,
  statusId?: string | null,
  progressionLabels: Record<TEnumValue, string>,
  useStatusQuery:() => UseQueryResult<Status<TEnumValue>[], unknown>,
  useSelectStatusMutation: () => UseMutationResult<unknown, unknown, {parentId: string, statusId: string}>,
  useNewStatusMutation: () => UseMutationResult<unknown, unknown, { label:string, color: string, progress: TEnumValue}>,
  useUpdateStatusMutation: () => UseMutationResult<unknown, unknown, {statusId: string, label: string, color: string, progress: TEnumValue}>,
  onAfterSelectStatusMutation: (oldStatus: Status<TEnumValue>, updatedStatus: Status<TEnumValue>) => Promise<unknown> | unknown,
  onAfterNewStatusMutation?: (newStatus: StatusBody<TEnumValue>) => Promise<unknown> | unknown,
  onAfterUpdateStatusMutation?: (oldStatus: PersistedStatus<TEnumValue>, updatedStatus: PersistedStatus<TEnumValue>) => Promise<unknown> | unknown,
}

const StatusWidget = <TEnum extends Record<string, string>, TEnumValue extends TEnum[keyof TEnum]> ({ parentId, statusId, progressionLabels, useStatusQuery,useSelectStatusMutation, useNewStatusMutation, useUpdateStatusMutation, onAfterSelectStatusMutation, onAfterNewStatusMutation, onAfterUpdateStatusMutation }:StatusWidgetProps<TEnum, TEnumValue>) => {
  const enumEntries = useMemo<[keyof TEnum, TEnumValue][]>(() => objectEntries(progressionLabels), [ progressionLabels ])
  const defaultProgressionValue = enumEntries[0][1]
  const defaultProgressionKey = enumEntries[0][0]

  const [ anchorEl, setAnchorEl ] = useState(null)
  const [ statusWeaver, setStatusWeaver ] = useState<keyof TEnum>()
  const [ statusUpdateWeaver, setStatusUpdateWeaver ] = useState<keyof TEnum>(defaultProgressionKey)
  const [ draftStatus, setDraftStatus ] = useState<StatusBody<TEnumValue>>({ label:"", progress:defaultProgressionValue, color: "" })
  const [ updateStatus, setUpdateStatus ] = useState<Status<TEnumValue>>({ id:"", label:"", progress:defaultProgressionValue, color: "" })
  const [ currentStatus, setCurrentStatus ] = useState<Status<TEnumValue>>({ id:"", label:"", progress:defaultProgressionValue, color: "" })
  const query = useStatusQuery()

  const selectStatusMutation = useSelectStatusMutation()
  const newStatusMutation = useNewStatusMutation()
  const updateStatusMutation = useUpdateStatusMutation()

  const selectStatus = useCallback(async (_e:unknown, data:Status<TEnumValue>) => {


    if (data.id){
      const oldStatus = { ...currentStatus }
      const newStatus = { ...data }
      await selectStatusMutation.mutateAsync({ parentId:parentId, statusId: data.id })
      await onAfterSelectStatusMutation(oldStatus, newStatus)
      await setCurrentStatus(data)
      await query.refetch()
      await setAnchorEl(null)
    }
  }, [ query.refetch, currentStatus, setCurrentStatus ])

type DataType = { [key: string]: Status<TEnumValue>[] }

const data = useMemo(() => {
  const data: DataType = {}

  query.data?.forEach((item) => {


    if (!data[item.progress]){
      data[item.progress] = [ item ]
    } else {
      data[item.progress] = [ ...data[item.progress], item ]
    }
    if (item.id === statusId) setCurrentStatus(item)
  })


  return data || []
}, [ query.data ])

useEffect(() => {
  if (!query.data && !query.isLoading){
    query.refetch()
  }

},[ query.data ])

const open = Boolean(anchorEl)

const handleClick = (e: React.MouseEvent) => setAnchorEl(e.currentTarget as any)
const handleClose = (_e: unknown, data?: Status<TEnumValue>) => {
  if (data) setCurrentStatus(data)
  setAnchorEl(null)
}

const onSubmitNewStatus = useCallback(async () => {
  if (draftStatus){
    await newStatusMutation.mutateAsync({  ...draftStatus })
    !!onAfterNewStatusMutation && await onAfterNewStatusMutation(draftStatus)
    await query.refetch()
  }

}, [ query.refetch, draftStatus, setDraftStatus ])

const handleSelectedEditStatus = async (id: string) => {
  if (id && query.data){
    const result = query.data.find(obj => {
      return obj.id === id
    })
    if (result) {
      await setUpdateStatus(result)
      await setStatusUpdateWeaver(result.progress)
    }
  }
}

const handleEditStatus = useCallback(async () => {
  if (updateStatus?.id){
    const oldStatus = { statusId: updateStatus.id, ...currentStatus }
    const newStatus = { statusId: updateStatus.id, ...updateStatus }
    await updateStatusMutation.mutateAsync(newStatus)
    !!onAfterUpdateStatusMutation && await onAfterUpdateStatusMutation(oldStatus, newStatus)
    await query.refetch()
  }
}, [ query.refetch, updateStatus, setUpdateStatus ])


return <>
  {currentStatus && (
    <Button
      variant="contained"
      onClick={handleClick}
      style={{ backgroundColor: !statusId ? grey[400] : colourValues[currentStatus.color], color: "black" }}
    >
      <Typography noWrap sx={{ textTransform: "none" }}>
        { !statusId ? "Select status" : currentStatus.label }
      </Typography>
    </Button>
  )}

  <Menu anchorEl={anchorEl} open={open} onClose={(e) => handleClose(e)}>
    {objectEntries(data).map(([ weaverStatus ]) => {
      return (
        <div key={JSON.stringify(data[weaverStatus])}>
          <StyledMenuItem>{progressionLabels[weaverStatus as TEnumValue]}</StyledMenuItem>
          { data[weaverStatus].map((status) => (

            <Box key={status.id} style={{ display:"flex", flexDirection:"row", justifyContent: "space-between", alignItems: "center" }}>
              <MenuItem onClick={(e) => selectStatus(e, status)}><div style={{ backgroundColor: colourValues[status.color] , paddingLeft: "7px", paddingRight: "7px", borderRadius: "4px", fontSize: "0.9em" }}>{status.label}</div></MenuItem>
              <NestedMenuItem
                key={status.id}
                parentMenuOpen={open}
                button="isIconOnly"
                rightIcon={<MoreVert />}
                onClick={() => handleSelectedEditStatus(status.id)}
              >
                <StyledEditMenu>
                  <TextField onClick={(e) => e.stopPropagation()} value={updateStatus?.label}   onChange={(e) => setUpdateStatus( (prev) => ({  ...prev, label:e.target.value }))}
                    placeholder="Status name..." variant="outlined" fullWidth size="small" />
                  <div style={{ width: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel size='small'>Progress</InputLabel>
                      <Select
                        onClick={(e) => e.stopPropagation()}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={statusUpdateWeaver}
                        label="Progress"
                        fullWidth
                        size='small'
                        onChange={(e: SelectChangeEvent<keyof TEnum>) => {
                        // Casting to the enum because the SelectChangeEvent resolves to `e.target.value: string | TEnum[keyof TEnum]`
                        // We can be sure this is safe because we use objectEntries of the same TEnum below
                          const enumChangedTo = e.target.value as TEnumValue
                          setStatusUpdateWeaver(enumChangedTo)
                          setUpdateStatus(prev => ({  ...prev, progress: enumChangedTo }))
                        }}
                      >
                        {objectEntries(progressionLabels).map(([ enumValue, label ]) => {
                        // Confirming that progressionLabels is of type TEnum
                          const typedEnum: TEnum[keyof TEnum] = enumValue
                          return <MenuItem key={typedEnum} value={typedEnum}>{label as string}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={updateStatus?.color}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => setUpdateStatus( (prev) => ({  ...prev, color: e.target.value }))}
                    >
                      {objectEntries(colourValues).map(([ color, hex ]) => (
                        <FormControlLabel key={color} value={color} control={<Radio sx={{
                          color: hex,
                          '&.Mui-checked': {
                            color: hex,
                          },
                        }} />} label={color} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <Button onClick={handleEditStatus}  fullWidth size="small" variant="contained">Save</Button>
                </StyledEditMenu>
              </NestedMenuItem>
            </Box>
          ))}
        </div>
      )
    })}
    <NestedMenuItem
      rightIcon={<Add />}
      label="Add"
      parentMenuOpen={open}
      button
    >
      <StyledEditMenu>
        <TextField onClick={(e) => e.stopPropagation()} placeholder="Status name..." value={draftStatus.label} onChange={(e) => setDraftStatus( (prev) => ({  ...prev, label:e.target.value }))} variant="outlined" fullWidth size="small" />
        <div style={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel size='small'>Progress</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statusWeaver}
              label="Progress"
              fullWidth
              size='small'
              placeholder='Please select...'
              onClick={(e) => e.stopPropagation()}
              onChange={(e: SelectChangeEvent<keyof TEnum>) => {
              // Casting to the enum because the SelectChangeEvent resolves to `e.target.value: string | TEnum[keyof TEnum]`
              // We can be sure this is safe because we use objectEntries of the same enum
                const enumChangedTo = e.target.value as TEnumValue
                setStatusWeaver(enumChangedTo)
                setDraftStatus(prev => ({  ...prev, progress: enumChangedTo }))
              }}
            >
              {objectEntries(progressionLabels).map(([ enumValue, label ]) => {
              // Confirming that progressionLabels is of type TEnum
                const typedEnum: TEnum[keyof TEnum] = enumValue
                return <MenuItem key={typedEnum} value={typedEnum}>{label as string}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        {/* TO-DO: map through these */}
        <FormControl>
          <RadioGroup
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={(e) => setDraftStatus( (prev) => ({  ...prev, color: e.target.value }))}
          >
            {objectEntries(colourValues).map(([ color, hex ]) => (
              <FormControlLabel key={color} value={color} control={<Radio sx={{
                color: hex,
                '&.Mui-checked': {
                  color: hex,
                },
              }} />} label={color} />
            ))}
          </RadioGroup>
        </FormControl>
        <Button onClick={onSubmitNewStatus} fullWidth size="small" variant="contained">Add new</Button>
      </StyledEditMenu>
    </NestedMenuItem>
  </Menu>
</>
}

export default StatusWidget
