import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { useRequiredContext } from '../../../common/hooks/useRequiredContext'
import { ProjectMemberRole, useRemoveProjectMemberMutation } from '../../../graphql/generated'
import ClearIcon from "@mui/icons-material/Clear"
import { ManageMemberState } from '../types'
import { ProjectsIndexContext } from '../ProjectsIndexContext'
import TeamTypeChip from '../TeamTypeChip'

export const ManageMember: React.FC<{modalState: ManageMemberState}> = ({ modalState }) => {
  const { handleModalSuccess } = useRequiredContext(ProjectsIndexContext)

  const { project, member } = modalState

  const coreApi = useCoreApiSource()
  const removeProjectMember = useRemoveProjectMemberMutation(coreApi)

  const [ isConfirmingClear, setConfirming ] = useState(false)
  
  const handleRemove = async () => {
    await removeProjectMember.mutateAsync({ id: member.id })
    setConfirming(false)
    handleModalSuccess(project.id)
  }

  const handleConfirmCancel = () => setConfirming(false)

  const isMemberRemovable = modalState.member.projectRole !== ProjectMemberRole.Owner

  return <>
    <DialogTitle>Project Member</DialogTitle>
    <DialogContent>
      
      <Typography variant="h5" component="div">
        {member.team.name} <TeamTypeChip team={member.team} />
      </Typography>
    </DialogContent>
    <DialogActions>
      {isMemberRemovable && <Button onClick={() => setConfirming(true)}><ClearIcon /> Remove From Project</Button>}
    </DialogActions>
       
    <Dialog
      open={isConfirmingClear}
      onClose={handleConfirmCancel}
    >
      <DialogTitle>Remove Member</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you&apos;d like to remove {member.projectRole} {member.team.name || "Unknown Name"} from project {project.title}?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmCancel}>Cancel</Button>
        <Button onClick={handleRemove} autoFocus>Remove</Button>
      </DialogActions>
    </Dialog>

  </>
}