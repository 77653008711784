import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React, { useCallback, useMemo, useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import { Alert, Avatar, Card, CardActions, CardContent,  LinearProgress, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DateTime } from 'luxon'
import { Individual } from '../../../graphql/generated'
import { getInitials } from '../../utils/text'
import { UseMutationResult, UseQueryResult } from 'react-query'

export type Note = {
  id: string,
  body: string,
  createdAt: string,
  updatedAt: string | undefined,
  createdByIndividual: Pick<Individual, "givenName" | "familyName" | "pictureURL">,
}

type NoteCardProps = {
  note: Note,
}

const NoteCard: React.FC<NoteCardProps> = ({ note }) => {
  const individual = note.createdByIndividual
  const name = `${individual.givenName} ${individual.familyName}`.trim() || "Unknown"
  const initials = getInitials(name)
  const createdAt = useMemo(() => DateTime.fromISO(note.createdAt), [ note.createdAt ])

  return (
    <Card variant='outlined' sx={{ width: "100%", flexShrink: 0 }}>
      <CardContent>
        <Typography variant="body2">{note.body}</Typography>
      </CardContent>
      <CardActions>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 1, width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 1 }}>
            <Avatar sx={{ bgcolor: "primary" }} src={individual.pictureURL}>{initials}</Avatar>
            {name}
            <Tooltip title={createdAt.toLocaleString(DateTime.DATETIME_FULL)}><Typography color="text.secondary">{createdAt.toRelative()}</Typography></Tooltip>
          </Box>

        </Box>
      </CardActions>
    </Card>
  )
}

export type NotesWidgetProps = {
  initialNoteCount?: number,
  parentId: string,
  title: string,
  useListNotesQuery: (id: string) => UseQueryResult<Note[], unknown>,
  useAddNoteMutation: () => UseMutationResult<unknown, unknown, {parentId: string, body: string}>,
  onAfterAddNoteMutation: () => Promise<unknown> | unknown,
}

export const NotesWidget: React.FC<NotesWidgetProps> = ({ initialNoteCount, parentId, useListNotesQuery, useAddNoteMutation, onAfterAddNoteMutation, title }) => {
  const [ isOpen, setOpen ] = useState(false)
  const [ draftNote, setDraftNote ] = useState("")
  const query = useListNotesQuery( parentId )

  const noteCount = useMemo(() => query.data?.length || initialNoteCount || "", [ query.data?.length ])
  const draftNoteEmpty = draftNote.trim() === ""

  const data = useMemo(() => {
    const data = query.data?.map(x => ({ ...x, createdAtDateTime: DateTime.fromISO(x.createdAt) }) )
      .sort( (a,b) => a.createdAtDateTime < b.createdAtDateTime ? 1 : a.createdAtDateTime > b.createdAtDateTime ? -1 : 0)
    return data || []
  }, [ query.data ])

  const addNoteMutation = useAddNoteMutation()

  const openNotes = useCallback(async () => {
    if (!query.data || query.isStale ) {
      query.refetch()
    }
    setOpen(true)
  }, [ query.data, query.isStale ])

  const addNote = useCallback(async () => {
    try {
      await addNoteMutation.mutateAsync({ parentId, body: draftNote })
      await onAfterAddNoteMutation()
    } catch (e: unknown) {
      return
    }
    setDraftNote("")
    await query.refetch()

  }, [ query.refetch, draftNote, setDraftNote ])

  return (
    <>
      <Button disabled={isOpen} onClick={() => openNotes()}>{noteCount} Notes</Button>
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        fullWidth={true}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>{addNoteMutation.error instanceof Error && <Alert severity="error">{addNoteMutation.error.message}</Alert>}</Grid>
            <Grid item xs={12}>
              <TextField type="textarea" disabled={addNoteMutation.isLoading} multiline={true} sx={{ width: "100%" }} value={draftNote} onChange={(event) => setDraftNote(event.target.value)} />
              <DialogActions>
                <Button color='primary' disabled={addNoteMutation.isLoading || draftNoteEmpty} onClick={() => addNote()}>Save</Button>
              </DialogActions>
            </Grid>
            <Grid item xs={12} sx={{ height: "1rem", mb: 1 }}>{ <LinearProgress sx={{ width: "100%", transition: "opacity 0.1s linear", opacity: query.isFetching ? 1 : 0 }} /> }</Grid>
            <Grid item xs={12}>
              {query.error instanceof Error && <Alert severity="error">{query.error.message}</Alert>}
            </Grid>
            <Grid item xs={12} sx={{ overflow: "auto", width: "100%", maxHeight: "50vh", pb: 1, gap: 1, display: "flex", flexDirection: "column" }}>
              { data.map(note => <NoteCard key={note.id} note={note} />) }
            </Grid>
          </Grid>


        </DialogContent>
      </Dialog>
    </>
  )
}

export default NotesWidget
