import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import useAbortableEffect from "./useAbortableEffect"

type TUsePageStateInternal<T> = {
  data: T,
  initialized: boolean,
}

type TUsePageStateReturns<T> = [
  T,
  (mergeState: Partial<T>) => void,
]

function isEntryValid(entry: [k: string, v: unknown]): boolean {
  const [ , value ] = entry
  return value !== undefined
}

function buildSantizedSearchParams(state: object): URLSearchParams {
  const validEntries = Object.entries(state).filter(x => isEntryValid(x))
  console.debug([ "useSearchState] built", state, validEntries ])
  return new URLSearchParams(validEntries)
}

export function useSearchState<T extends object>(initialData: T): TUsePageStateReturns<T> {
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ internalState, setInternalState ] = useState<TUsePageStateInternal<T>>({ data: initialData, initialized: false })

  const mergeNextData = (nextData: Partial<T>) => {
    const nextInternalState = {
      ...internalState,
      // If the change is coming from a component, we have to assume it's been initialized, otherwise we'll be waiting forever
      initialized: true,
      data: {
        ...internalState.data,
        ...nextData,
      },
    }
    console.debug("[useSearchState] mergeNext: ", searchParams, internalState)
    setInternalState(nextInternalState)
  }

  // bind URL => state
  useAbortableEffect(() => {
    async function setStateFromUrl() {
      const incomingData = Object.fromEntries(searchParams)

      // build a valid next state (missing fields should be defaulted)
      const normalizedState = {
        ...initialData,
        ...incomingData,
      }

      console.debug("[useSearchState] << setting internal state to", normalizedState)
      setInternalState({ data: normalizedState, initialized: true })
    }

    setStateFromUrl()
  },[ searchParams ])



  useEffect(() => {
    if (!internalState.initialized) {
      return
    }
    console.debug("[useSearchState] >> setting search state to", internalState.data)

    setSearchParams(buildSantizedSearchParams(internalState.data), { replace: true })
  }, [ internalState.data ])

  return [ internalState.data, mergeNextData ]
}
