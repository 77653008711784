import React, { useMemo } from 'react'
import { Button, ButtonGroup, Card, CardContent, Grid } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { useGetStripeProductConfigsQuery, GetStripeProductConfigsQuery, Money, Currency, useClearStripeProductConfigCacheMutation } from '../../../graphql/generated'

type StripeProductConfig = GetStripeProductConfigsQuery["getStripeProductConfigs"][number]

const localeForCurrency: Record<Currency, string> = {
  EUR: 'de-DE', // using Germany
  GBP: 'en-GB',
  USD: 'en-US',
}

const displayPrice = (money: Money): string =>
  Intl.NumberFormat(localeForCurrency[money.currency], {
    style: 'currency',
    currency: String(money.currency).toUpperCase(),
  }).format(parseInt(money.amountInPence) / 100)

const StripeIndex: React.FC = () => {
  const gqlDatasource = useCoreApiSource()

  const clearStripeProductConfigCache = useClearStripeProductConfigCacheMutation(gqlDatasource)

  const getStripeProductConfigsQuery = useGetStripeProductConfigsQuery(gqlDatasource, {}, {
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnWindowFocus: false,
  })

  const refreshStripeProductData = async () => {
    await clearStripeProductConfigCache.mutateAsync({})
    await getStripeProductConfigsQuery.refetch()
    // This doesnt seem to update the rows. You need to refresh.
  }

  const columns = useMemo<GridColDef<StripeProductConfig>[]>(() => [
    { sortable: false, flex: 1, minWidth: 300, field: 'product', headerName: 'Weaver Product Code', renderCell: (params) => <div>{params.row.product}</div> },
    { sortable: false, flex: 1, minWidth: 200, field: 'productId', headerName: 'Stripe Product ID', renderCell: (params) => <div><a href={`https://dashboard.stripe.com/${params.row.isLive ? '' : 'test/'}products/${params.row.productId}`}>{params.row.productId}</a></div> },
    { sortable: false, flex: 1, minWidth: 300, field: 'priceId', headerName: 'Stripe Price ID', renderCell: (params) => <div><a href={`https://dashboard.stripe.com/${params.row.isLive ? '' : 'test/'}prices/${params.row.priceId}`}>{params.row.priceId}</a></div> },
    { sortable: false, flex: 1, minWidth: 150, field: 'price', headerName: 'Price', renderCell: (params) => <div>{displayPrice(params.row.price)}</div> },
    { sortable: false, flex: 1, minWidth: 50, field: 'isRecurring', headerName: 'Recurring', renderCell: (params) => <div>{params.row.isRecurring ? '✅ (Subscription)' : '❌ (Per Lead)'}</div> },
  ], [])

  const data = getStripeProductConfigsQuery.data?.getStripeProductConfigs || []

  return <>
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <DataGrid
                  getRowId={row => row.product}
                  rows={data}
                  columns={columns}
                  disableSelectionOnClick={true}
                  rowHeight={110}
                  autoHeight={true}
                  disableColumnMenu
                />
              </Grid>
            </Grid>
            <ButtonGroup style={{ marginTop: '1rem' }}>
              <Button variant="contained" onClick={refreshStripeProductData}>Refresh Stripe Product Data</Button>
            </ButtonGroup>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>
}

export default StripeIndex
