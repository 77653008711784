import React from 'react'
import {
  Link,
  Navigate,
  Route,
  Routes,
} from "react-router-dom"
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import ProjectsIndex from './domains/projects/ProjectsIndex'
import { AuthContext } from './Bootstrapper'
import { AppBar, Toolbar, IconButton, Typography, Tooltip, Avatar, Menu, Button, MenuItem, Grid } from '@mui/material'

import Container from '@mui/system/Container'
import Box from '@mui/system/Box'
import MenuIcon from "@mui/icons-material/Menu"
import { useRequiredContext } from './common/hooks/useRequiredContext'
import Logo from "./logo.png"

import { ReactQueryDevtools } from 'react-query/devtools'
import ReferencesIndex from './domains/references/ReferencesIndex'
import BuildersIndex from './domains/builders/BuildersIndex'
import EngineeringIndex from './domains/engineering/EngineeringIndex'
import ReleasesIndex from './domains/engineering/releases/ReleasesIndex'
import StripeIndex from './domains/engineering/stripe/StripeIndex'
import BackgroundJobsIndex from './domains/engineering/backgroundJobs/BackgroundJobsIndex'
import TaskTemplatesIndex from './domains/engineering/taskTemplates/taskTemplatesIndex'
import ShowTaskTemplate from './domains/engineering/taskTemplates/editTaskTemplate'

const App: React.FC = () => {
  const { auth0, individual } = useRequiredContext(AuthContext)

  const [ anchorElNav, setAnchorElNav ] = React.useState<null | HTMLElement>(null)
  const [ anchorElUser, setAnchorElUser ] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    auth0.logout()
    handleCloseNavMenu()
  }

  return <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <img src={Logo} alt="logo" style={{ maxHeight: "1.5rem" , marginRight: "2rem" }} />

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  <Link to='/projects'>
                    <MenuItem key="projects" onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Projects</Typography>
                    </MenuItem>
                  </Link>
                  <Link to='/builders'>
                    <MenuItem key="builders" onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Builders</Typography>
                    </MenuItem>
                  </Link>
                  <Link to='/references'>
                    <MenuItem key="references" onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">References</Typography>
                    </MenuItem>
                  </Link>
                  <Link to='/engineering'>
                    <MenuItem key="engineering" onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">Engineering</Typography>
                    </MenuItem>
                  </Link>
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Link to='/projects'>
                  <Button key="projects" onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                    projects
                  </Button>
                </Link>
                <Link to='/builders'>
                  <Button key="builders" onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }} >
                    Builders
                  </Button>
                </Link>
                <Link to='/references'>
                  <Button key="references" onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                    References
                  </Button>
                </Link>
                <Link to='/engineering'>
                  <Button key="engineering" onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                    Engineering
                  </Button>
                </Link>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={`${individual.givenName} ${individual.familyName}`} src={individual.pictureURL} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key="logout" onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Grid item xs={12}>
          <Routes>
            <Route index element={<Navigate replace to={"/projects"} />} />
            <Route path="/projects" element={<ProjectsIndex />} />
            <Route path="/builders" element={<BuildersIndex />} />
            <Route path="/references" element={<ReferencesIndex />} />
            <Route path="/engineering" element={<EngineeringIndex />} />
            <Route path="/engineering/releases" element={<ReleasesIndex />} />
            <Route path="/engineering/stripe" element={<StripeIndex />} />
            <Route path="/engineering/backgroundJobs" element={<BackgroundJobsIndex />} />
            <Route path="/engineering/taskTemplates" element={<TaskTemplatesIndex />} />
            <Route path="/engineering/taskTemplates/:taskTemplateId" element={<ShowTaskTemplate />} />
          </Routes>
        </Grid>
      </Grid>
    </Grid>
    <ReactQueryDevtools initialIsOpen={false} />
  </>
}

export default App
