import React, { useMemo } from 'react'
import { ProjectMemberRole } from '../../../graphql/generated'

import { getInitials } from '../../../common/utils/text'
import Tooltip from '@mui/material/Tooltip'
import { useRequiredContext } from '../../../common/hooks/useRequiredContext'
import { ProjectsIndexContext } from '../ProjectsIndexContext'
import { Project } from "../types"
import { sortByCreated, sorted } from "../../../common/utils/sort"
import PersonIcon from "@mui/icons-material/Person"
import Styles from "./CommonMemberDisplays.module.scss"
import { Typography } from '@mui/material'


type BuilderDisplayProps = {
  project: Project,
}
const OwnerDisplay: React.FC<BuilderDisplayProps> = ({ project }) => {
  const { handleManageMember } = useRequiredContext(ProjectsIndexContext)

  const members = useMemo(() => sorted(project.members.filter(x => x.projectRole === ProjectMemberRole.Owner), sortByCreated), [ project.members ])

  return (
    <div className={Styles.container}>
      <div className={Styles.chipRow}>
        <div className={Styles.rowIcon}><PersonIcon /></div>

        {members.map(x => {
          return <Tooltip sx={{ bgcolor: "text.secondary", color: "white" }} className={Styles.item} key={x.team.id} title={`${x.projectRole}: ${x.team.name || "Unknown"}`} onClick={() => handleManageMember(project, x)}>
            <Typography>{getInitials(x.team.name || "X")}</Typography>
          </Tooltip>
        })}
      </div>

    </div>
  )
}

export default OwnerDisplay
