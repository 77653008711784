import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    })

    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0]

      throw new Error(message)
    }

    return json.data
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  AWSDate: string,
  AWSDateTime: string,
  AWSEmail: string,
  AWSIPAddress: string,
  AWSJSON: string,
  AWSPhone: string,
  AWSTime: string,
  AWSTimestamp: number,
  AWSURL: string,
};

export type AddProjectSlotInput = {
  projectId: Scalars['ID'],
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
};

export type ArchitectProfile = {
  __typename?: 'ArchitectProfile',
  companyAddress?: Maybe<WeaverAddress>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type AssignProjectToWeaverUserInput = {
  projectId: Scalars['ID'],
  weaverTeamId: Scalars['ID'],
};

export type BackgroundJob = {
  __typename?: 'BackgroundJob',
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  finishedAt?: Maybe<Scalars['AWSDateTime']>,
  id: Scalars['ID'],
  payload?: Maybe<Scalars['AWSJSON']>,
  payloadAsDataIntegrityCheckerReport?: Maybe<BackgroundJobPayloadAsDataIntegrityCheckerReport>,
  startedAt?: Maybe<Scalars['AWSDateTime']>,
  status: BackgroundJobStatus,
  type: BackgroundJobType,
  updatedAt: Scalars['AWSDateTime'],
};

export enum BackgroundJobOutcome {
  Failed = 'Failed',
  Success = 'Success'
}

export type BackgroundJobPayloadAsDataIntegrityCheckerReport = {
  __typename?: 'BackgroundJobPayloadAsDataIntegrityCheckerReport',
  errors: Array<BackgroundJobPayloadAsDataIntegrityCheckerReportTable>,
  failedTableCount: Scalars['Int'],
  successTableCount: Scalars['Int'],
  totalTableCount: Scalars['Int'],
};

export type BackgroundJobPayloadAsDataIntegrityCheckerReportTable = {
  __typename?: 'BackgroundJobPayloadAsDataIntegrityCheckerReportTable',
  errorRowIds?: Maybe<Array<Scalars['String']>>,
  name: Scalars['String'],
};

export enum BackgroundJobStatus {
  Finished = 'Finished',
  InProgress = 'InProgress',
  Waiting = 'Waiting'
}

export enum BackgroundJobType {
  DataIntegrityCheckerReport = 'DataIntegrityCheckerReport'
}

export enum BadgeLevel {
  Bronze = 'Bronze',
  Gold = 'Gold',
  None = 'None',
  Silver = 'Silver'
}

export enum BudgetCategory {
  Exact = 'Exact',
  NoIdea = 'NoIdea',
  RoughIdea = 'RoughIdea'
}

export enum BudgetRange {
  F30T100 = 'F30T100',
  F100T500 = 'F100T500',
  F500T1000 = 'F500T1000',
  F1000T3000 = 'F1000T3000'
}

export type CachedWeaverCoordinates = {
  __typename?: 'CachedWeaverCoordinates',
  address: WeaverAddress,
  createdAt: Scalars['AWSDateTime'],
  exactCoordinates: WeaverCoordinates,
  fudgedCoordinates: WeaverCoordinates,
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ChatRoom = {
  __typename?: 'ChatRoom',
  _lastMessageContent?: Maybe<Scalars['String']>,
  _lastMessageIdFromClient?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  icon?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  individualConnections: Array<IndividualChatRoomConnection>,
  lastMessageAt: Scalars['AWSDateTime'],
  messages?: Maybe<Array<ChatRoomMessage>>,
  myChatRoomStats?: Maybe<ChatRoomStats>,
  name?: Maybe<Scalars['String']>,
  project?: Maybe<Project>,
  projectId?: Maybe<Scalars['ID']>,
  teamConnections: Array<TeamChatRoomConnection>,
  updatedAt: Scalars['AWSDateTime'],
};

export type ChatRoomMessage = {
  __typename?: 'ChatRoomMessage',
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  content: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  documentCount?: Maybe<Scalars['Int']>,
  documentIds?: Maybe<Array<Scalars['String']>>,
  documents?: Maybe<Array<UploadedFile>>,
  id: Scalars['ID'],
  idFromClient: Scalars['ID'],
  readAt?: Maybe<Scalars['AWSDateTime']>,
  receivedByIndividual: Individual,
  receivedByIndividualId: Scalars['ID'],
  sentAt: Scalars['AWSDateTime'],
  sentByIndividual: Individual,
  sentByIndividualId: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ChatRoomStats = {
  __typename?: 'ChatRoomStats',
  unreadMessagesCount?: Maybe<Scalars['Int']>,
};

export type CompaniesHouseResponse = {
  __typename?: 'CompaniesHouseResponse',
  apiHealthy: Scalars['Boolean'],
  results: Array<CompanySummary>,
};

export type CompanySummary = {
  __typename?: 'CompanySummary',
  accounts_AccountCategory?: Maybe<Scalars['String']>,
  accounts_AccountRefDay?: Maybe<Scalars['String']>,
  accounts_AccountRefMonth?: Maybe<Scalars['String']>,
  accounts_LastMadeUpDate?: Maybe<Scalars['String']>,
  accounts_NextDueDate?: Maybe<Scalars['String']>,
  companyCategory?: Maybe<Scalars['String']>,
  companyName: Scalars['String'],
  companyNumber: Scalars['String'],
  companyStatus?: Maybe<Scalars['String']>,
  confStmtLastMadeUpDate?: Maybe<Scalars['String']>,
  confStmtNextDueDate?: Maybe<Scalars['String']>,
  countryOfOrigin?: Maybe<Scalars['String']>,
  dissolutionDate?: Maybe<Scalars['String']>,
  incorporationDate?: Maybe<Scalars['String']>,
  limitedPartnerships_NumGenPartners?: Maybe<Scalars['String']>,
  limitedPartnerships_NumLimPartners?: Maybe<Scalars['String']>,
  mortgages_NumMortCharges?: Maybe<Scalars['String']>,
  mortgages_NumMortOutstanding?: Maybe<Scalars['String']>,
  mortgages_NumMortPartSatisfied?: Maybe<Scalars['String']>,
  mortgages_NumMortSatisfied?: Maybe<Scalars['String']>,
  previousName_1_CONDATE?: Maybe<Scalars['String']>,
  previousName_1_CompanyName?: Maybe<Scalars['String']>,
  previousName_2_CONDATE?: Maybe<Scalars['String']>,
  previousName_2_CompanyName?: Maybe<Scalars['String']>,
  previousName_3_CONDATE?: Maybe<Scalars['String']>,
  previousName_3_CompanyName?: Maybe<Scalars['String']>,
  previousName_4_CONDATE?: Maybe<Scalars['String']>,
  previousName_4_CompanyName?: Maybe<Scalars['String']>,
  previousName_5_CONDATE?: Maybe<Scalars['String']>,
  previousName_5_CompanyName?: Maybe<Scalars['String']>,
  previousName_6_CONDATE?: Maybe<Scalars['String']>,
  previousName_6_CompanyName?: Maybe<Scalars['String']>,
  previousName_7_CONDATE?: Maybe<Scalars['String']>,
  previousName_7_CompanyName?: Maybe<Scalars['String']>,
  previousName_8_CONDATE?: Maybe<Scalars['String']>,
  previousName_8_CompanyName?: Maybe<Scalars['String']>,
  previousName_9_CONDATE?: Maybe<Scalars['String']>,
  previousName_9_CompanyName?: Maybe<Scalars['String']>,
  previousName_50_CONDATE?: Maybe<Scalars['String']>,
  previousName_50_CompanyName?: Maybe<Scalars['String']>,
  regAddress_AddressLine1?: Maybe<Scalars['String']>,
  regAddress_AddressLine2?: Maybe<Scalars['String']>,
  regAddress_CareOf?: Maybe<Scalars['String']>,
  regAddress_Country?: Maybe<Scalars['String']>,
  regAddress_County?: Maybe<Scalars['String']>,
  regAddress_POBox?: Maybe<Scalars['String']>,
  regAddress_PostCode?: Maybe<Scalars['String']>,
  regAddress_PostTown?: Maybe<Scalars['String']>,
  returns_LastMadeUpDate?: Maybe<Scalars['String']>,
  returns_NextDueDate?: Maybe<Scalars['String']>,
  sicCode_SicText_1?: Maybe<Scalars['String']>,
  sicCode_SicText_2?: Maybe<Scalars['String']>,
  sicCode_SicText_3?: Maybe<Scalars['String']>,
  sicCode_SicText_4?: Maybe<Scalars['String']>,
  uri?: Maybe<Scalars['String']>,
};

export enum ContentDisposition {
  Attachment = 'Attachment',
  Inline = 'Inline'
}

export enum ContractorCompanyCheckProgress {
  Fail = 'Fail',
  NotChecked = 'NotChecked',
  Success = 'Success'
}

export type ContractorCompanyCheckStatus = {
  __typename?: 'ContractorCompanyCheckStatus',
  color: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  label: Scalars['String'],
  progress: ContractorCompanyCheckProgress,
  updatedAt: Scalars['AWSDateTime'],
};

export type ContractorInvite = {
  companyName: Scalars['String'],
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  phone: Scalars['String'],
};

export type ContractorProfile = {
  __typename?: 'ContractorProfile',
  _notesCount?: Maybe<Scalars['Int']>,
  acceptedLeads?: Maybe<Scalars['Int']>,
  budgetRangeBadges?: Maybe<Array<ContractorProfileBudgetRangeBadge>>,
  budgetRangeSubscriptions?: Maybe<Array<ContractorProfileBudgetRangeSubscription>>,
  companyAddress?: Maybe<WeaverAddress>,
  companyCheckStatus?: Maybe<ContractorCompanyCheckStatus>,
  companyCheckStatusId?: Maybe<Scalars['ID']>,
  companyIncorporationDate?: Maybe<Scalars['AWSDateTime']>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  locationAreas?: Maybe<Array<Scalars['String']>>,
  onboardingPaymentEvent?: Maybe<Event>,
  rejectedLeads?: Maybe<Scalars['Int']>,
  status?: Maybe<ContractorStatus>,
  statusId?: Maybe<Scalars['ID']>,
  updatedAt: Scalars['AWSDateTime'],
  workHistory?: Maybe<Array<ContractorProfileWorkHistory>>,
};

export type ContractorProfileBudgetRangeBadge = {
  __typename?: 'ContractorProfileBudgetRangeBadge',
  awardedAt: Scalars['AWSDateTime'],
  awardedForWorkHistoryId: Scalars['ID'],
  badgeLevel?: Maybe<BadgeLevel>,
  budgetRange: BudgetRange,
  id: Scalars['ID'],
};

export type ContractorProfileBudgetRangeSubscription = {
  __typename?: 'ContractorProfileBudgetRangeSubscription',
  budgetRange: BudgetRange,
  id: Scalars['ID'],
};

export type ContractorProfileLookup = {
  __typename?: 'ContractorProfileLookup',
  contractorProfile?: Maybe<ContractorProfile>,
  contractorProfileId?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
  workHistory?: Maybe<ContractorProfileWorkHistory>,
  workHistoryId?: Maybe<Scalars['ID']>,
  workHistoryReference?: Maybe<ContractorProfileWorkHistoryReference>,
  workHistoryReferenceId?: Maybe<Scalars['ID']>,
};

export type ContractorProfileLookupInput = {
  contractorProfileId?: InputMaybe<Scalars['ID']>,
  workHistoryId?: InputMaybe<Scalars['ID']>,
  workHistoryReferenceId?: InputMaybe<Scalars['ID']>,
};

export type ContractorProfileNote = OpsNote & {
  __typename?: 'ContractorProfileNote',
  body: Scalars['String'],
  contractorProfileId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ContractorProfileWorkHistory = {
  __typename?: 'ContractorProfileWorkHistory',
  constructionCompletedDate?: Maybe<Scalars['AWSDateTime']>,
  constructionStartedDate?: Maybe<Scalars['AWSDateTime']>,
  constructionValue?: Maybe<Money>,
  id: Scalars['ID'],
  isArchived?: Maybe<Scalars['Boolean']>,
  photoIds?: Maybe<Array<Scalars['String']>>,
  photos?: Maybe<Array<UploadedFile>>,
  projectAddress?: Maybe<WeaverAddress>,
  projectTypes?: Maybe<Array<ProjectType>>,
  references?: Maybe<Array<ContractorProfileWorkHistoryReference>>,
};

export type ContractorProfileWorkHistoryReference = {
  __typename?: 'ContractorProfileWorkHistoryReference',
  _notesCount?: Maybe<Scalars['Int']>,
  companyName?: Maybe<Scalars['String']>,
  contractorProfileWorkHistoryReferenceStatusId?: Maybe<Scalars['ID']>,
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  id: Scalars['ID'],
  phoneNumber: Scalars['String'],
  status: ProgressionStatus,
  teamType: TeamType,
};

export type ContractorProfileWorkHistoryReferenceNote = OpsNote & {
  __typename?: 'ContractorProfileWorkHistoryReferenceNote',
  body: Scalars['String'],
  contractorProfileWorkHistoryReferenceId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ContractorProfileWorkHistoryReferenceStatus = {
  __typename?: 'ContractorProfileWorkHistoryReferenceStatus',
  color: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  label: Scalars['String'],
  progress: ProgressionStatus,
  updatedAt: Scalars['AWSDateTime'],
};

export enum ContractorProgress {
  Fail = 'Fail',
  NotChecked = 'NotChecked',
  Success = 'Success'
}

export type ContractorStatus = {
  __typename?: 'ContractorStatus',
  color: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  label: Scalars['String'],
  progress: ContractorProgress,
  updatedAt: Scalars['AWSDateTime'],
};

export enum CorporateStructureType {
  LimitedCompany = 'LimitedCompany',
  Other = 'Other',
  Partnership = 'Partnership',
  SoleTrader = 'SoleTrader'
}

export type CreateArchitectProfile = {
  architectTeamId: Scalars['ID'],
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: InputMaybe<Scalars['String']>,
};

export type CreateChatRoom = {
  icon?: InputMaybe<Scalars['String']>,
  name: Scalars['String'],
  projectId?: InputMaybe<Scalars['ID']>,
  teamIds: Array<Scalars['ID']>,
};

export type CreateContractorProfile = {
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyIncorporationDate?: InputMaybe<Scalars['AWSDateTime']>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: InputMaybe<Scalars['String']>,
  contractorTeamId: Scalars['ID'],
};

export type CreateContractorProfileWorkHistory = {
  constructionCompletedDate?: InputMaybe<Scalars['AWSDateTime']>,
  constructionStartedDate?: InputMaybe<Scalars['AWSDateTime']>,
  constructionValue: MoneyInput,
  projectAddress?: InputMaybe<WeaverAddressInput>,
  projectTypes: Array<ProjectType>,
};

export type CreateContractorProfileWorkHistoryReference = {
  companyName?: InputMaybe<Scalars['String']>,
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  phoneNumber: Scalars['String'],
  teamType: TeamType,
};

export type CreateContractorProfileWorkHistoryReferenceForWorkHistory = {
  id: Scalars['ID'],
  reference: CreateContractorProfileWorkHistoryReference,
};

export type CreateHomeownerProfile = {
  homeownerTeamId: Scalars['ID'],
  propertyAddress?: InputMaybe<WeaverAddressInput>,
  teamName?: InputMaybe<Scalars['String']>,
};

export type CreateProjectInput = {
  address: WeaverAddressInput,
  budgetCategory: Scalars['String'],
  budgetValue: MoneyInput,
  contractorInvites: Array<ContractorInvite>,
  description: Scalars['String'],
  projectTypes: Array<ProjectType>,
  taskTemplateIds?: InputMaybe<Array<Scalars['ID']>>,
  tenderReturnDate: Scalars['AWSDateTime'],
  totalContractors: Scalars['Int'],
  workStartEstimate: WorkStartEstimate,
};

export type CreateProjectTaskInput = {
  actionableType: TaskActionableType,
  assignedTeamId?: InputMaybe<Scalars['ID']>,
  dueAt?: InputMaybe<Scalars['AWSDateTime']>,
  icon?: InputMaybe<Scalars['String']>,
  order: Scalars['Int'],
  parentTaskId?: InputMaybe<Scalars['ID']>,
  projectId: Scalars['ID'],
  status: TaskStatus,
  title: Scalars['String'],
  url?: InputMaybe<Scalars['String']>,
};

export type CreateTaskTemplateInput = {
  body: Scalars['String'],
  id: Scalars['ID'],
  trigger: TaskTemplateTrigger,
};

export type CreateUploadedFileInput = {
  description?: InputMaybe<Scalars['String']>,
  fileContentType?: InputMaybe<Scalars['String']>,
  fileName?: InputMaybe<Scalars['String']>,
  fileSizeInBytes?: InputMaybe<Scalars['Int']>,
  tags?: InputMaybe<Array<CreateUploadedFileTagInput>>,
  title?: InputMaybe<Scalars['String']>,
};

export type CreateUploadedFileTagInput = {
  confidence?: InputMaybe<Scalars['Float']>,
  name: Scalars['String'],
  source: UploadedFileTagSource,
};

export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type DataIntegrityCheckerReportTable = {
  __typename?: 'DataIntegrityCheckerReportTable',
  aborted: Scalars['Boolean'],
  backgroundJobId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  errorRowIds?: Maybe<Array<Scalars['String']>>,
  errored?: Maybe<Scalars['Boolean']>,
  id: Scalars['ID'],
  name: Scalars['String'],
  outcome: BackgroundJobOutcome,
  updatedAt: Scalars['AWSDateTime'],
};

export type DownloadConfigInput = {
  disposition?: InputMaybe<ContentDisposition>,
  transformation?: InputMaybe<FileTransformationInput>,
};

export type DraftProject = {
  __typename?: 'DraftProject',
  _notesCount?: Maybe<Scalars['Int']>,
  acceptedQuoteId?: Maybe<Scalars['ID']>,
  address?: Maybe<WeaverAddress>,
  assigneeTeamId?: Maybe<Scalars['ID']>,
  budgetCategory?: Maybe<Scalars['String']>,
  budgetValue?: Maybe<Money>,
  createdAt: Scalars['AWSDateTime'],
  createdByIndividualId: Scalars['ID'],
  createdByTeamId: Scalars['ID'],
  description?: Maybe<Scalars['String']>,
  documentCount?: Maybe<Scalars['Int']>,
  documentIds?: Maybe<Array<Scalars['String']>>,
  id: Scalars['ID'],
  lastAccessed?: Maybe<Scalars['AWSDateTime']>,
  note?: Maybe<Scalars['String']>,
  projectDraftState: ProjectDraftState,
  projectTypes?: Maybe<Array<ProjectType>>,
  quotes?: Maybe<Array<Quote>>,
  reference?: Maybe<Scalars['String']>,
  siteVisits?: Maybe<Array<SiteVisit>>,
  status?: Maybe<ProjectProgress>,
  statusId?: Maybe<Scalars['ID']>,
  tenderReturnDate?: Maybe<Scalars['AWSDateTime']>,
  title?: Maybe<Scalars['String']>,
  updatedAt: Scalars['AWSDateTime'],
  workStartEstimate?: Maybe<WorkStartEstimate>,
};

export type Event = {
  __typename?: 'Event',
  actionedAt?: Maybe<Scalars['AWSDateTime']>,
  actionedByIndividualId?: Maybe<Scalars['ID']>,
};

export type EventInput = {
  actionedAt?: InputMaybe<Scalars['AWSDateTime']>,
  actionedByIndividualId?: InputMaybe<Scalars['ID']>,
};

export type FileTransformation = {
  __typename?: 'FileTransformation',
  height?: Maybe<Scalars['Int']>,
  ignoreAspectRatio?: Maybe<Scalars['Boolean']>,
  s3SubKey?: Maybe<Scalars['String']>,
  transformedContentType?: Maybe<Scalars['String']>,
  transformedExtension?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Int']>,
};

export type FileTransformationInput = {
  height?: InputMaybe<Scalars['Int']>,
  ignoreAspectRatio?: InputMaybe<Scalars['Boolean']>,
  width?: InputMaybe<Scalars['Int']>,
};

export type HomeownerProfile = {
  __typename?: 'HomeownerProfile',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  propertyAddress?: Maybe<WeaverAddress>,
  teamName?: Maybe<Scalars['String']>,
  updatedAt: Scalars['AWSDateTime'],
};

export type IncrementChatRoomMessageDocumentCount = {
  chatRoomMessageId: Scalars['ID'],
  documentCount: Scalars['Int'],
};

export type IncrementProjectDocumentCount = {
  documentCount: Scalars['Int'],
  projectId: Scalars['ID'],
};

export type Individual = {
  __typename?: 'Individual',
  _hasUnreadChatRoomMessages?: Maybe<Scalars['Boolean']>,
  chatRoomConnections: Array<IndividualChatRoomConnection>,
  createdAt: Scalars['AWSDateTime'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  id: Scalars['ID'],
  lastSyncedAt: Scalars['AWSDateTime'],
  pictureURL: Scalars['String'],
  teamConnections: Array<IndividualTeamConnection>,
  updatedAt: Scalars['AWSDateTime'],
};

export type IndividualChatRoomConnection = {
  __typename?: 'IndividualChatRoomConnection',
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individual: Individual,
  individualId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type IndividualTeamConnection = {
  __typename?: 'IndividualTeamConnection',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individual: Individual,
  individualId: Scalars['ID'],
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type InviteToMyProjectInput = {
  companyName?: InputMaybe<Scalars['String']>,
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  phone: Scalars['String'],
  projectId: Scalars['ID'],
};

export enum LambdaResolver {
  Google = 'Google',
  Primary = 'Primary'
}

export type Lead = {
  __typename?: 'Lead',
  address?: Maybe<WeaverAddress>,
  budgetCategory: Scalars['String'],
  budgetValue: Money,
  description: Scalars['String'],
  id: Scalars['ID'],
  lastAccessed?: Maybe<Scalars['AWSDateTime']>,
  members: Array<ProjectMember>,
  projectTypes: Array<ProjectType>,
  referral?: Maybe<Referral>,
  tenderReturnDate: Scalars['AWSDateTime'],
  title: Scalars['String'],
  workStartEstimate: WorkStartEstimate,
};

export type ListAllContractorProfilesFilter = {
  companyNameContains?: InputMaybe<Scalars['String']>,
};

export type ListAllOpsWorkHistoryReferencesFilter = {
  idEquals?: InputMaybe<Scalars['String']>,
  nameContains?: InputMaybe<Scalars['String']>,
};

export type ListAllProjectsFilter = {
  assigneeTeamIdEquals?: InputMaybe<Scalars['ID']>,
  titleContains?: InputMaybe<Scalars['String']>,
  typeEquals?: InputMaybe<Scalars['String']>,
};

export enum LocationArea {
  LondonCentral = 'LondonCentral',
  LondonEast = 'LondonEast',
  LondonNorth = 'LondonNorth',
  LondonSouth = 'LondonSouth',
  LondonWest = 'LondonWest'
}

export type MessageSentInfo = {
  __typename?: 'MessageSentInfo',
  recipients?: Maybe<Array<Maybe<MessageSentInfoRecipient>>>,
};

export type MessageSentInfoRecipient = {
  __typename?: 'MessageSentInfoRecipient',
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  individualId: Scalars['ID'],
  teamId: Scalars['ID'],
};

export type Money = {
  __typename?: 'Money',
  amountInPence: Scalars['String'],
  currency: Currency,
};

export type MoneyInput = {
  amountInPence: Scalars['String'],
  currency: Currency,
};

export type Mutation = {
  __typename?: 'Mutation',
  acceptLead: Scalars['Boolean'],
  addContractorCompanyCheckStatus: ContractorCompanyCheckStatus,
  addContractorProfileNote: Scalars['Boolean'],
  addContractorProfileWorkHistoryReferenceNote: Scalars['Boolean'],
  addContractorProfileWorkHistoryReferenceStatus: ContractorProfileWorkHistoryReferenceStatus,
  addContractorStatus: ContractorStatus,
  addProjectInvite: Scalars['Boolean'],
  addProjectMember: Scalars['Boolean'],
  addProjectNote: Scalars['Boolean'],
  addProjectSlot: Scalars['Boolean'],
  addUploadedFileToProjectFiles?: Maybe<UploadedFile>,
  addUploadedFilesToChatRoomMessage?: Maybe<UploadedFile>,
  assignProjectToWeaverUser: Scalars['Boolean'],
  awardContractorProfileBudgetRangeBadge: Scalars['Boolean'],
  claimCompanyTeam: Team,
  claimInvite: Scalars['Boolean'],
  claimNonCompanyTeam: Team,
  clearStripeProductConfigCache: Scalars['Boolean'],
  createArchitectProfile: ArchitectProfile,
  createChatRoom: ChatRoom,
  createContractorProfile: ContractorProfile,
  createContractorProfileWorkHistory: ContractorProfileWorkHistory,
  createContractorProfileWorkHistoryPhoto?: Maybe<UploadedFile>,
  createContractorProfileWorkHistoryReference: ContractorProfileWorkHistoryReference,
  createDraftProject: DraftProject,
  createHomeownerProfile: HomeownerProfile,
  createNewProjectStatus: Scalars['Boolean'],
  createProject: Project,
  createProjectTask: Scalars['Boolean'],
  createStripeSessionForLeadPayment: StripeSessionResponse,
  createTaskTemplate: TaskTemplate,
  createUploadedFile: UploadedFile,
  flushCachesFrontendSPA: Scalars['Boolean'],
  incrementChatRoomMessageDocumentCount: Scalars['Boolean'],
  incrementProjectDocumentCount: Scalars['Boolean'],
  inviteToMyProject: ProjectMemberInvite,
  joinChatRoom?: Maybe<ChatRoom>,
  joinChatRoomForIndividual?: Maybe<ChatRoom>,
  joinWeaverTeam: Team,
  markChatRoomMessageAsRead: Scalars['Boolean'],
  processProjectAction: Scalars['Boolean'],
  publishDraftProject: PublishedProject,
  registerMyDeviceForPushNotifications: PushNotificationDevice,
  rejectLead: Scalars['Boolean'],
  removeContractorProfileSubscriptionsForBudgetRange: Scalars['Boolean'],
  removeIndividualFromTeam: Scalars['Boolean'],
  removeProjectInvite: Scalars['Boolean'],
  removeProjectMember: Scalars['Boolean'],
  removeProjectSlot: Scalars['Boolean'],
  removeUploadedFileTags: UploadedFile,
  resetContractorProfileWorkHistory: Scalars['Boolean'],
  selectContractorCompanyCheckStatus: Scalars['Boolean'],
  selectContractorProfileWorkHistoryReferenceStatus: Scalars['Boolean'],
  selectContractorStatus: Scalars['Boolean'],
  selectProjectStatus: Scalars['Boolean'],
  sendChatRoomMessage?: Maybe<MessageSentInfo>,
  setIndividualPhoneNumber: Scalars['Boolean'],
  setOnboardingComplete: Scalars['Boolean'],
  setTaskStatus: Scalars['Boolean'],
  startDataIntegrityCheckerReport: BackgroundJob,
  triggerErrorForSentry: Scalars['Boolean'],
  updateArchitectProfile: ArchitectProfile,
  updateChatRoom?: Maybe<ChatRoom>,
  updateContractorCompanyCheckStatus: Scalars['Boolean'],
  updateContractorProfile: ContractorProfile,
  updateContractorProfileWorkHistory: ContractorProfileWorkHistory,
  updateContractorProfileWorkHistoryReference: ContractorProfileWorkHistoryReference,
  updateContractorProfileWorkHistoryReferenceStatus: Scalars['Boolean'],
  updateContractorStatus: Scalars['Boolean'],
  updateDraftProject: DraftProject,
  updateHomeownerProfile: HomeownerProfile,
  updateProjectStatus: Scalars['Boolean'],
  updateProjectTaskStatus: Scalars['Boolean'],
  updateProjectWorkflow: Scalars['Boolean'],
  updateTaskTemplate: Scalars['Boolean'],
  updateUploadedFile: UploadedFile,
};


export type MutationAcceptLeadArgs = {
  projectId: Scalars['ID'],
};


export type MutationAddContractorCompanyCheckStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ContractorCompanyCheckProgress,
};


export type MutationAddContractorProfileNoteArgs = {
  body: Scalars['String'],
  contractorProfileId: Scalars['ID'],
};


export type MutationAddContractorProfileWorkHistoryReferenceNoteArgs = {
  body: Scalars['String'],
  contractorProfileWorkHistoryReferenceId: Scalars['ID'],
};


export type MutationAddContractorProfileWorkHistoryReferenceStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ProgressionStatus,
};


export type MutationAddContractorStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ContractorProgress,
};


export type MutationAddProjectInviteArgs = {
  projectId: Scalars['ID'],
  projectMemberRole: ProjectMemberRole,
  teamId: Scalars['ID'],
};


export type MutationAddProjectMemberArgs = {
  projectId: Scalars['ID'],
  projectMemberRole: ProjectMemberRole,
  teamId: Scalars['ID'],
};


export type MutationAddProjectNoteArgs = {
  body: Scalars['String'],
  projectId: Scalars['ID'],
};


export type MutationAddProjectSlotArgs = {
  input: AddProjectSlotInput,
};


export type MutationAddUploadedFileToProjectFilesArgs = {
  projectId: Scalars['ID'],
  uploadedFileId: Scalars['ID'],
};


export type MutationAddUploadedFilesToChatRoomMessageArgs = {
  chatRoomMessageId: Scalars['ID'],
  uploadedFileIds: Array<Scalars['ID']>,
};


export type MutationAssignProjectToWeaverUserArgs = {
  input?: InputMaybe<AssignProjectToWeaverUserInput>,
};


export type MutationAwardContractorProfileBudgetRangeBadgeArgs = {
  awardedBadge?: InputMaybe<BadgeLevel>,
  budgetRange: BudgetRange,
  isMultiBadge: Scalars['Boolean'],
  workHistoryId: Scalars['ID'],
};


export type MutationClaimCompanyTeamArgs = {
  companyNumber: Scalars['String'],
  name: Scalars['String'],
  referringInviteId?: InputMaybe<Scalars['ID']>,
  type: TeamType,
};


export type MutationClaimInviteArgs = {
  inviteId: Scalars['ID'],
};


export type MutationClaimNonCompanyTeamArgs = {
  name: Scalars['String'],
  referringInviteId?: InputMaybe<Scalars['ID']>,
  type: TeamType,
};


export type MutationCreateArchitectProfileArgs = {
  input: CreateArchitectProfile,
};


export type MutationCreateChatRoomArgs = {
  input: CreateChatRoom,
};


export type MutationCreateContractorProfileArgs = {
  input: CreateContractorProfile,
};


export type MutationCreateContractorProfileWorkHistoryArgs = {
  input: CreateContractorProfileWorkHistory,
};


export type MutationCreateContractorProfileWorkHistoryPhotoArgs = {
  uploadedFileId: Scalars['ID'],
  workHistoryId: Scalars['ID'],
};


export type MutationCreateContractorProfileWorkHistoryReferenceArgs = {
  input: CreateContractorProfileWorkHistoryReferenceForWorkHistory,
};


export type MutationCreateHomeownerProfileArgs = {
  input: CreateHomeownerProfile,
};


export type MutationCreateNewProjectStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ProjectProgress,
};


export type MutationCreateProjectArgs = {
  project: CreateProjectInput,
};


export type MutationCreateProjectTaskArgs = {
  input: CreateProjectTaskInput,
};


export type MutationCreateStripeSessionForLeadPaymentArgs = {
  leadId: Scalars['ID'],
  product: StripeProduct,
  redirectBaseURL: Scalars['String'],
};


export type MutationCreateTaskTemplateArgs = {
  input: CreateTaskTemplateInput,
};


export type MutationCreateUploadedFileArgs = {
  input: CreateUploadedFileInput,
};


export type MutationIncrementChatRoomMessageDocumentCountArgs = {
  input: IncrementChatRoomMessageDocumentCount,
};


export type MutationIncrementProjectDocumentCountArgs = {
  input: IncrementProjectDocumentCount,
};


export type MutationInviteToMyProjectArgs = {
  input: InviteToMyProjectInput,
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
};


export type MutationJoinChatRoomArgs = {
  chatRoomId: Scalars['ID'],
};


export type MutationJoinChatRoomForIndividualArgs = {
  chatRoomId: Scalars['ID'],
  individualId: Scalars['ID'],
};


export type MutationMarkChatRoomMessageAsReadArgs = {
  chatRoomMessageId: Scalars['ID'],
};


export type MutationProcessProjectActionArgs = {
  action: ProjectActionInput,
};


export type MutationPublishDraftProjectArgs = {
  id: Scalars['ID'],
};


export type MutationRegisterMyDeviceForPushNotificationsArgs = {
  deviceToken: Scalars['String'],
};


export type MutationRejectLeadArgs = {
  projectId: Scalars['ID'],
};


export type MutationRemoveContractorProfileSubscriptionsForBudgetRangeArgs = {
  budgetRange: BudgetRange,
  contractorProfileId: Scalars['ID'],
};


export type MutationRemoveIndividualFromTeamArgs = {
  individualId: Scalars['String'],
  teamId: Scalars['String'],
};


export type MutationRemoveProjectInviteArgs = {
  id: Scalars['ID'],
};


export type MutationRemoveProjectMemberArgs = {
  id: Scalars['ID'],
};


export type MutationRemoveProjectSlotArgs = {
  id: Scalars['ID'],
};


export type MutationRemoveUploadedFileTagsArgs = {
  id: Scalars['ID'],
};


export type MutationSelectContractorCompanyCheckStatusArgs = {
  contractorCompanyCheckStatusId: Scalars['ID'],
  contractorProfileId: Scalars['ID'],
};


export type MutationSelectContractorProfileWorkHistoryReferenceStatusArgs = {
  contractorProfileWorkHistoryReferenceId: Scalars['ID'],
  contractorProfileWorkHistoryReferenceStatusId: Scalars['ID'],
};


export type MutationSelectContractorStatusArgs = {
  contractorProfileId: Scalars['ID'],
  contractorStatusId: Scalars['ID'],
};


export type MutationSelectProjectStatusArgs = {
  parentId: Scalars['ID'],
  statusId: Scalars['ID'],
};


export type MutationSendChatRoomMessageArgs = {
  message: SendChatRoomMessage,
};


export type MutationSetIndividualPhoneNumberArgs = {
  individualId?: InputMaybe<Scalars['ID']>,
  phoneNumber: Scalars['String'],
};


export type MutationSetOnboardingCompleteArgs = {
  isOnboardingComplete: Scalars['Boolean'],
  teamId: Scalars['String'],
};


export type MutationSetTaskStatusArgs = {
  newStatus: TaskStatus,
  taskId: Scalars['ID'],
};


export type MutationUpdateArchitectProfileArgs = {
  input: UpdateArchitectProfile,
};


export type MutationUpdateChatRoomArgs = {
  input: UpdateChatRoom,
};


export type MutationUpdateContractorCompanyCheckStatusArgs = {
  color: Scalars['String'],
  contractorCompanyCheckStatusId: Scalars['ID'],
  label: Scalars['String'],
  progress: ContractorCompanyCheckProgress,
};


export type MutationUpdateContractorProfileArgs = {
  input: UpdateContractorProfile,
};


export type MutationUpdateContractorProfileWorkHistoryArgs = {
  input: UpdateContractorProfileWorkHistory,
};


export type MutationUpdateContractorProfileWorkHistoryReferenceArgs = {
  input: UpdateContractorProfileWorkHistoryReferenceForWorkHistory,
};


export type MutationUpdateContractorProfileWorkHistoryReferenceStatusArgs = {
  color: Scalars['String'],
  contractorProfileWorkHistoryReferenceStatusId: Scalars['ID'],
  label: Scalars['String'],
  progress: ProgressionStatus,
};


export type MutationUpdateContractorStatusArgs = {
  color: Scalars['String'],
  contractorStatusId: Scalars['ID'],
  label: Scalars['String'],
  progress: ContractorProgress,
};


export type MutationUpdateDraftProjectArgs = {
  project: UpdateDraftProjectInput,
};


export type MutationUpdateHomeownerProfileArgs = {
  input: UpdateHomeownerProfile,
};


export type MutationUpdateProjectStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ProjectProgress,
  statusId: Scalars['ID'],
};


export type MutationUpdateProjectTaskStatusArgs = {
  input: UpdateProjectTaskStatus,
};


export type MutationUpdateProjectWorkflowArgs = {
  input: UpdateProjectWorkflowInput,
};


export type MutationUpdateTaskTemplateArgs = {
  input: CreateTaskTemplateInput,
};


export type MutationUpdateUploadedFileArgs = {
  input: UpdateUploadedFileInput,
};

export type OpsNote = {
  body: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type OpsWorkHistoryReference = {
  __typename?: 'OpsWorkHistoryReference',
  _notesCount?: Maybe<Scalars['Int']>,
  companyName?: Maybe<Scalars['String']>,
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: Maybe<Scalars['String']>,
  constructionValue?: Maybe<Money>,
  contractorProfileId: Scalars['ID'],
  contractorProfileWorkHistoryId: Scalars['ID'],
  contractorProfileWorkHistoryReferenceStatusId?: Maybe<Scalars['ID']>,
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  id: Scalars['ID'],
  phoneNumber: Scalars['String'],
  status: ProgressionStatus,
  teamType: TeamType,
};

export enum ProgressionStatus {
  Completed = 'Completed',
  NotStarted = 'NotStarted',
  WaitingOnUser = 'WaitingOnUser',
  WaitingOnWeaver = 'WaitingOnWeaver'
}

export type Project = {
  __typename?: 'Project',
  _notesCount?: Maybe<Scalars['Int']>,
  acceptedQuoteId?: Maybe<Scalars['ID']>,
  address?: Maybe<WeaverAddress>,
  assigneeTeamId?: Maybe<Scalars['ID']>,
  budgetCategory: Scalars['String'],
  budgetValue: Money,
  createdAt: Scalars['AWSDateTime'],
  description: Scalars['String'],
  documentCount?: Maybe<Scalars['Int']>,
  documentIds?: Maybe<Array<Scalars['String']>>,
  documents?: Maybe<Array<UploadedFile>>,
  id: Scalars['ID'],
  lastAccessed?: Maybe<Scalars['AWSDateTime']>,
  lastActivity?: Maybe<Scalars['AWSDateTime']>,
  memberInvites: Array<ProjectMemberInvite>,
  memberSlots: Array<ProjectMemberSlot>,
  members: Array<ProjectMember>,
  note?: Maybe<Scalars['String']>,
  projectTypes: Array<ProjectType>,
  quotes?: Maybe<Array<Quote>>,
  reference: Scalars['String'],
  siteVisits?: Maybe<Array<SiteVisit>>,
  status?: Maybe<ProjectProgress>,
  statusId?: Maybe<Scalars['ID']>,
  tasks: Array<Task>,
  tenderReturnDate: Scalars['AWSDateTime'],
  title: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
  workStartEstimate: WorkStartEstimate,
};


export type ProjectMemberInvitesArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type ProjectMembersArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type ProjectTasksArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};

export type ProjectAction = {
  __typename?: 'ProjectAction',
  actionId: Scalars['String'],
  projectId: Scalars['ID'],
  teamId?: Maybe<Scalars['ID']>,
};

export type ProjectActionInput = {
  actionId: Scalars['String'],
  projectId: Scalars['ID'],
};

export enum ProjectDraftState {
  Draft = 'Draft',
  Published = 'Published'
}

export type ProjectMember = {
  __typename?: 'ProjectMember',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  inviteReferral?: Maybe<Referral>,
  projectId: Scalars['ID'],
  projectRole: ProjectMemberRole,
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ProjectMemberInvite = {
  __typename?: 'ProjectMemberInvite',
  companyName?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  email?: Maybe<Scalars['String']>,
  familyName?: Maybe<Scalars['String']>,
  givenName?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  phone?: Maybe<Scalars['String']>,
  projectId: Scalars['ID'],
  referral?: Maybe<Referral>,
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
  team?: Maybe<Team>,
  teamId?: Maybe<Scalars['ID']>,
  updatedAt: Scalars['AWSDateTime'],
};

export enum ProjectMemberRole {
  CandidateProfessional = 'CANDIDATE_PROFESSIONAL',
  Owner = 'OWNER',
  Professional = 'PROFESSIONAL'
}

export type ProjectMemberSlot = {
  __typename?: 'ProjectMemberSlot',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  projectId: Scalars['ID'],
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
  updatedAt: Scalars['AWSDateTime'],
};

export type ProjectNote = OpsNote & {
  __typename?: 'ProjectNote',
  body: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  id: Scalars['ID'],
  projectId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum ProjectProgress {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type ProjectStatus = {
  __typename?: 'ProjectStatus',
  color: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  label: Scalars['String'],
  progress: ProjectProgress,
  updatedAt: Scalars['AWSDateTime'],
};

export enum ProjectType {
  BasementConversion = 'BasementConversion',
  ChangeOfUse = 'ChangeOfUse',
  Conservatory = 'Conservatory',
  FullRenovation = 'FullRenovation',
  LoftConversion = 'LoftConversion',
  NewBuild = 'NewBuild',
  RearExtension = 'RearExtension',
  Refurbishment = 'Refurbishment',
  SideExtension = 'SideExtension'
}

export type ProjectWorkflow = {
  __typename?: 'ProjectWorkflow',
  createdAt: Scalars['AWSDateTime'],
  projectId: Scalars['ID'],
  triggers: Array<WorkflowTrigger>,
  updatedAt: Scalars['AWSDateTime'],
};

export type PublishedProject = {
  __typename?: 'PublishedProject',
  _notesCount?: Maybe<Scalars['Int']>,
  acceptedQuoteId?: Maybe<Scalars['ID']>,
  address?: Maybe<WeaverAddress>,
  assigneeTeamId?: Maybe<Scalars['ID']>,
  budgetCategory: Scalars['String'],
  budgetValue: Money,
  createdAt: Scalars['AWSDateTime'],
  description: Scalars['String'],
  documentCount?: Maybe<Scalars['Int']>,
  documentIds?: Maybe<Array<Scalars['String']>>,
  documents?: Maybe<Array<UploadedFile>>,
  id: Scalars['ID'],
  lastAccessed?: Maybe<Scalars['AWSDateTime']>,
  lastActivity?: Maybe<Scalars['AWSDateTime']>,
  memberInvites: Array<ProjectMemberInvite>,
  memberSlots: Array<ProjectMemberSlot>,
  members: Array<ProjectMember>,
  note?: Maybe<Scalars['String']>,
  projectTypes: Array<ProjectType>,
  quotes?: Maybe<Array<Quote>>,
  reference: Scalars['String'],
  siteVisits?: Maybe<Array<SiteVisit>>,
  status?: Maybe<ProjectProgress>,
  statusId?: Maybe<Scalars['ID']>,
  tasks: Array<Task>,
  tenderReturnDate: Scalars['AWSDateTime'],
  title: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
  workStartEstimate: WorkStartEstimate,
};


export type PublishedProjectMemberInvitesArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type PublishedProjectMembersArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type PublishedProjectTasksArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};

export type PushNotificationDevice = {
  __typename?: 'PushNotificationDevice',
  createdAt: Scalars['AWSDateTime'],
  deviceToken: Scalars['String'],
  id: Scalars['ID'],
  individualId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum PushNotificationType {
  NewChatRoomMessage = 'new_ChatRoomMessage'
}

export type Query = {
  __typename?: 'Query',
  getArchitectProfile?: Maybe<ArchitectProfile>,
  getBackgroundJob: BackgroundJob,
  getChatRoom?: Maybe<ChatRoom>,
  getChatRoomForProjectTeams?: Maybe<ChatRoom>,
  getChatRoomMessages?: Maybe<Array<ChatRoomMessage>>,
  getContractorProfile?: Maybe<ContractorProfile>,
  getCoordinatesForWeaverAddress: WeaverCoordinates,
  getDraftProject: DraftProject,
  getHomeownerProfile?: Maybe<HomeownerProfile>,
  getLead: Lead,
  getMyIndividual?: Maybe<Individual>,
  getPossibleCompanyNumbersForPartialCompanyName: CompaniesHouseResponse,
  getProject: Project,
  getProjectForChatRoom?: Maybe<Project>,
  getPublishedProject: PublishedProject,
  getReleaseInfo: ReleaseInfo,
  getStripeProductConfigs: Array<StripeProductConfig>,
  getTask: Task,
  getTaskTemplate?: Maybe<TaskTemplate>,
  getTeam: Team,
  getUploadedFile: UploadedFile,
  hasCompanyNumberBeenClaimed: Scalars['Boolean'],
  listAllContractorCompanyCheckStatus: Array<ContractorCompanyCheckStatus>,
  listAllContractorProfileWorkHistoryReferenceNotes: Array<ContractorProfileWorkHistoryReferenceNote>,
  listAllContractorProfileWorkHistoryReferenceStatuses: Array<ContractorProfileWorkHistoryReferenceStatus>,
  listAllContractorProfiles: Array<ContractorProfile>,
  listAllContractorStatus: Array<ContractorStatus>,
  listAllIndividuals: Array<Individual>,
  listAllOpsWorkHistoryReferences: Array<OpsWorkHistoryReference>,
  listAllProjectStatus: Array<ProjectStatus>,
  listAllProjects: Array<Project>,
  listAllPublishedProjects: Array<PublishedProject>,
  listAllTaskTemplates: Array<TaskTemplate>,
  listAllTeams: Array<Team>,
  listAvailableChatRooms?: Maybe<Array<Maybe<ChatRoom>>>,
  listBackgroundJobs: Array<BackgroundJob>,
  listContractorProfileNotes: Array<ContractorProfileNote>,
  listContractorSuggestionsForProject: Array<ContractorProfile>,
  listMyChatRooms?: Maybe<Array<Maybe<IndividualChatRoomConnection>>>,
  listMyDraftProjects: Array<DraftProject>,
  listMyLeads: Array<Lead>,
  listMyProjects: Array<Project>,
  listMyPublishedProjects: Array<PublishedProject>,
  listProjectNotes: Array<ProjectNote>,
  listProjectTasks: Array<Task>,
  lookupContractorProfile: Array<ContractorProfileLookup>,
};


export type QueryGetArchitectProfileArgs = {
  architectTeamId: Scalars['ID'],
};


export type QueryGetBackgroundJobArgs = {
  backgroundJobId: Scalars['ID'],
};


export type QueryGetChatRoomArgs = {
  id: Scalars['ID'],
};


export type QueryGetChatRoomForProjectTeamsArgs = {
  projectId?: InputMaybe<Scalars['ID']>,
  teamIds: Array<Scalars['ID']>,
};


export type QueryGetChatRoomMessagesArgs = {
  chatRoomId: Scalars['ID'],
};


export type QueryGetContractorProfileArgs = {
  contractorTeamId: Scalars['ID'],
};


export type QueryGetCoordinatesForWeaverAddressArgs = {
  address: WeaverAddressInput,
  exact?: InputMaybe<Scalars['Boolean']>,
};


export type QueryGetDraftProjectArgs = {
  id: Scalars['ID'],
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type QueryGetHomeownerProfileArgs = {
  homeownerTeamId: Scalars['ID'],
};


export type QueryGetLeadArgs = {
  id: Scalars['ID'],
};


export type QueryGetPossibleCompanyNumbersForPartialCompanyNameArgs = {
  partialCompanyName: Scalars['String'],
};


export type QueryGetProjectArgs = {
  id: Scalars['ID'],
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type QueryGetProjectForChatRoomArgs = {
  id: Scalars['ID'],
};


export type QueryGetPublishedProjectArgs = {
  id: Scalars['ID'],
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};


export type QueryGetTaskArgs = {
  taskId: Scalars['ID'],
};


export type QueryGetTaskTemplateArgs = {
  id: Scalars['ID'],
};


export type QueryGetTeamArgs = {
  id: Scalars['ID'],
};


export type QueryGetUploadedFileArgs = {
  id: Scalars['ID'],
};


export type QueryHasCompanyNumberBeenClaimedArgs = {
  companyNumber: Scalars['String'],
};


export type QueryListAllContractorProfileWorkHistoryReferenceNotesArgs = {
  contractorProfileWorkHistoryReferenceId: Scalars['ID'],
};


export type QueryListAllContractorProfilesArgs = {
  filter?: InputMaybe<ListAllContractorProfilesFilter>,
};


export type QueryListAllOpsWorkHistoryReferencesArgs = {
  filter?: InputMaybe<ListAllOpsWorkHistoryReferencesFilter>,
};


export type QueryListAllProjectsArgs = {
  filter?: InputMaybe<ListAllProjectsFilter>,
};


export type QueryListAllPublishedProjectsArgs = {
  filter?: InputMaybe<ListAllProjectsFilter>,
};


export type QueryListContractorProfileNotesArgs = {
  contractorProfileId: Scalars['ID'],
};


export type QueryListContractorSuggestionsForProjectArgs = {
  projectId: Scalars['ID'],
};


export type QueryListProjectNotesArgs = {
  projectId: Scalars['ID'],
};


export type QueryListProjectTasksArgs = {
  projectId: Scalars['ID'],
};


export type QueryLookupContractorProfileArgs = {
  input: ContractorProfileLookupInput,
};

export type Quote = {
  __typename?: 'Quote',
  createdByTeamId: Scalars['ID'],
  id: Scalars['ID'],
};

export enum ReferenceRoleType {
  Architect = 'Architect',
  Homeowner = 'Homeowner'
}

export type Referral = {
  __typename?: 'Referral',
  teamId?: Maybe<Scalars['ID']>,
  type: ReferralType,
};

export enum ReferralType {
  NewUser = 'NewUser',
  ProjectOwner = 'ProjectOwner',
  Weaver = 'Weaver'
}

export type ReleaseInfo = {
  __typename?: 'ReleaseInfo',
  appFrontendVersion?: Maybe<Scalars['String']>,
  mechaVersion?: Maybe<Scalars['String']>,
  opsDashboardFrontendVersion?: Maybe<Scalars['String']>,
  opsDashboardInfrastructureVersion?: Maybe<Scalars['String']>,
  version: Scalars['String'],
};

export type SendChatRoomMessage = {
  chatRoomId: Scalars['ID'],
  content: Scalars['String'],
  documentCount?: InputMaybe<Scalars['Int']>,
  documentIds?: InputMaybe<Array<Scalars['ID']>>,
  idFromClient: Scalars['ID'],
  sentAt: Scalars['AWSDateTime'],
};

export type SignedUrl = {
  __typename?: 'SignedUrl',
  status: SignedUrlStatus,
  url?: Maybe<Scalars['String']>,
};

export enum SignedUrlStatus {
  ConfigError = 'ConfigError',
  FileNotFound = 'FileNotFound',
  NotDownloadable = 'NotDownloadable',
  Success = 'Success',
  TransformationMissing = 'TransformationMissing',
  UnsupportedOperationError = 'UnsupportedOperationError'
}

export type SiteVisit = {
  __typename?: 'SiteVisit',
  assignedTeamId: Scalars['ID'],
  dueDate: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  status: SiteVisitStatus,
};

export enum SiteVisitStatus {
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED'
}

export enum StatusColor {
  Blue = 'Blue',
  Green = 'Green',
  Red = 'Red',
  Yellow = 'Yellow'
}

export type StatusInput = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ProjectProgress,
};

export enum StripeCallback {
  Default = 'Default',
  LeadPayment = 'LeadPayment'
}

export enum StripeProduct {
  SingleLeadPaymentF30T100 = 'SingleLeadPaymentF30T100',
  SingleLeadPaymentF100T500 = 'SingleLeadPaymentF100T500',
  SingleLeadPaymentF500T1000 = 'SingleLeadPaymentF500T1000',
  SingleLeadPaymentF1000T3000 = 'SingleLeadPaymentF1000T3000',
  UnlimitedLeadSubscriptionF30T100 = 'UnlimitedLeadSubscriptionF30T100',
  UnlimitedLeadSubscriptionF100T500 = 'UnlimitedLeadSubscriptionF100T500',
  UnlimitedLeadSubscriptionF500T1000 = 'UnlimitedLeadSubscriptionF500T1000',
  UnlimitedLeadSubscriptionF1000T3000 = 'UnlimitedLeadSubscriptionF1000T3000'
}

export type StripeProductConfig = {
  __typename?: 'StripeProductConfig',
  isLive: Scalars['Boolean'],
  isRecurring: Scalars['Boolean'],
  price: Money,
  priceId: Scalars['String'],
  product: StripeProduct,
  productId: Scalars['String'],
};

export type StripeSession = {
  __typename?: 'StripeSession',
  callbackCancelAt?: Maybe<Scalars['AWSDateTime']>,
  callbackSuccessAt?: Maybe<Scalars['AWSDateTime']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individual: Individual,
  individualId: Scalars['ID'],
  payload: Scalars['AWSJSON'],
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type StripeSessionResponse = {
  __typename?: 'StripeSessionResponse',
  id: Scalars['String'],
  url?: Maybe<Scalars['String']>,
};

export type Task = {
  __typename?: 'Task',
  actionSummary?: Maybe<Scalars['String']>,
  actionTitle?: Maybe<Scalars['String']>,
  actionablePayload?: Maybe<Scalars['String']>,
  actionableType: TaskActionableType,
  activeStatus: TaskStatus,
  assignedTeam?: Maybe<Team>,
  assignedTeamId?: Maybe<Scalars['ID']>,
  childTasks: Array<Task>,
  createdAt: Scalars['AWSDateTime'],
  dueAt?: Maybe<Scalars['AWSDateTime']>,
  icon?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isConfidential?: Maybe<Scalars['Boolean']>,
  order: Scalars['Int'],
  parentTask?: Maybe<Task>,
  parentTaskId?: Maybe<Scalars['ID']>,
  project: Project,
  projectId: Scalars['ID'],
  status: TaskStatus,
  title: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
  url?: Maybe<Scalars['String']>,
};

export enum TaskActionableType {
  Contracting = 'Contracting',
  DownloadDocuments = 'DownloadDocuments',
  GenericManual = 'GenericManual',
  Hiring = 'Hiring',
  OpenMemberChatroom = 'OpenMemberChatroom',
  OpenProject = 'OpenProject',
  Shortlisting = 'Shortlisting',
  Tendering = 'Tendering'
}

export enum TaskStatus {
  Completed = 'COMPLETED',
  Disabled = 'DISABLED',
  InProgress = 'IN_PROGRESS',
  NextStep = 'NEXT_STEP',
  NotStarted = 'NOT_STARTED',
  Overdue = 'OVERDUE',
  Skipped = 'SKIPPED'
}

export type TaskTemplate = {
  __typename?: 'TaskTemplate',
  body: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  trigger: TaskTemplateTrigger,
  updatedAt: Scalars['AWSDateTime'],
};

export enum TaskTemplateTrigger {
  AcceptLead = 'AcceptLead',
  CreateProject = 'CreateProject'
}

export type Team = {
  __typename?: 'Team',
  companyNumber?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individualConnections: Array<IndividualTeamConnection>,
  initialReferral?: Maybe<Referral>,
  isOnboardingComplete?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  type: TeamType,
  updatedAt: Scalars['AWSDateTime'],
};

export type TeamChatRoomConnection = {
  __typename?: 'TeamChatRoomConnection',
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum TeamKind {
  Company = 'COMPANY',
  NonCompany = 'NON_COMPANY'
}

export enum TeamType {
  Architect = 'ARCHITECT',
  Contractor = 'CONTRACTOR',
  Homeowner = 'HOMEOWNER',
  Weaver = 'WEAVER'
}

export type UpdateArchitectProfile = {
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyTradingAs?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
};

export type UpdateChatRoom = {
  icon?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  name?: InputMaybe<Scalars['String']>,
};

export type UpdateContractorProfile = {
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyTradingAs?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  locationAreas?: InputMaybe<Array<Scalars['String']>>,
  onboardingPaymentEvent?: InputMaybe<EventInput>,
};

export type UpdateContractorProfileWorkHistory = {
  constructionValue?: InputMaybe<MoneyInput>,
  id: Scalars['ID'],
  isArchived?: InputMaybe<Scalars['Boolean']>,
  projectAddress?: InputMaybe<WeaverAddressInput>,
  projectTypes?: InputMaybe<Array<ProjectType>>,
};

export type UpdateContractorProfileWorkHistoryReference = {
  companyName?: InputMaybe<Scalars['String']>,
  email?: InputMaybe<Scalars['String']>,
  familyName?: InputMaybe<Scalars['String']>,
  givenName?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  phoneNumber?: InputMaybe<Scalars['String']>,
  status?: InputMaybe<ProgressionStatus>,
  teamType?: InputMaybe<TeamType>,
};

export type UpdateContractorProfileWorkHistoryReferenceForWorkHistory = {
  id: Scalars['ID'],
  reference: UpdateContractorProfileWorkHistoryReference,
};

export type UpdateDraftProjectInput = {
  address?: InputMaybe<WeaverAddressInput>,
  budgetCategory?: InputMaybe<Scalars['String']>,
  budgetValue?: InputMaybe<MoneyInput>,
  contractorInvites?: InputMaybe<Array<ContractorInvite>>,
  description?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  projectTypes?: InputMaybe<Array<ProjectType>>,
  tenderReturnDate?: InputMaybe<Scalars['AWSDateTime']>,
  totalContractors?: InputMaybe<Scalars['Int']>,
  workStartEstimate?: InputMaybe<WorkStartEstimate>,
};

export type UpdateHomeownerProfile = {
  id: Scalars['ID'],
  propertyAddress?: InputMaybe<WeaverAddressInput>,
  teamName?: InputMaybe<Scalars['String']>,
};

export type UpdateProjectTaskStatus = {
  projectId: Scalars['ID'],
  status: TaskStatus,
  taskId?: InputMaybe<Scalars['ID']>,
};

export type UpdateProjectWorkflowInput = {
  projectId: Scalars['ID'],
  triggers: Array<WorkflowTriggerInput>,
};

export type UpdateUploadedFileInput = {
  description?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  status?: InputMaybe<UploadedFileStatus>,
  tags?: InputMaybe<Array<UpdateUploadedFileTagInput>>,
  title?: InputMaybe<Scalars['String']>,
};

export type UpdateUploadedFileTagInput = {
  confidence?: InputMaybe<Scalars['Float']>,
  name: Scalars['String'],
  source: UploadedFileTagSource,
};

export type UploadedFile = {
  __typename?: 'UploadedFile',
  createdAt: Scalars['AWSDateTime'],
  description?: Maybe<Scalars['String']>,
  fileContentType?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  fileSizeInBytes?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  s3Bucket: Scalars['String'],
  s3Key: Scalars['String'],
  signedUrlForDownload: SignedUrl,
  signedUrlForUpload: SignedUrl,
  status?: Maybe<UploadedFileStatus>,
  tags?: Maybe<Array<UploadedFileTag>>,
  title?: Maybe<Scalars['String']>,
  transformations?: Maybe<Array<FileTransformation>>,
  updatedAt: Scalars['AWSDateTime'],
  uploadedByIndividual?: Maybe<Individual>,
  uploadedByIndividualId: Scalars['ID'],
  uploadedByTeam?: Maybe<Team>,
  uploadedByTeamId: Scalars['ID'],
};


export type UploadedFileSignedUrlForDownloadArgs = {
  config?: InputMaybe<DownloadConfigInput>,
};

export enum UploadedFileStatus {
  Archived = 'Archived',
  Completed = 'Completed',
  Failed = 'Failed',
  NotStarted = 'NotStarted',
  Uploading = 'Uploading'
}

export type UploadedFileTag = {
  __typename?: 'UploadedFileTag',
  confidence?: Maybe<Scalars['Float']>,
  name: Scalars['String'],
  source: UploadedFileTagSource,
};

export enum UploadedFileTagSource {
  MachineLearning = 'MachineLearning',
  User = 'User'
}

export type WeaverAddress = {
  __typename?: 'WeaverAddress',
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  careOf?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  county?: Maybe<Scalars['String']>,
  poBox?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  postTown?: Maybe<Scalars['String']>,
};

export type WeaverAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>,
  addressLine2?: InputMaybe<Scalars['String']>,
  careOf?: InputMaybe<Scalars['String']>,
  country?: InputMaybe<Scalars['String']>,
  county?: InputMaybe<Scalars['String']>,
  poBox?: InputMaybe<Scalars['String']>,
  postCode: Scalars['String'],
  postTown?: InputMaybe<Scalars['String']>,
};

export type WeaverCoordinates = {
  __typename?: 'WeaverCoordinates',
  lat: Scalars['String'],
  lng: Scalars['String'],
};

export enum WorkStartEstimate {
  EndOfMonth = 'END_OF_MONTH',
  OneThreeMonths = 'ONE_THREE_MONTHS',
  OverSixMonths = 'OVER_SIX_MONTHS',
  ThreeSixMonths = 'THREE_SIX_MONTHS',
  Unknown = 'UNKNOWN'
}

export type WorkflowTrigger = {
  __typename?: 'WorkflowTrigger',
  actionId: Scalars['String'],
  actionableType: Scalars['String'],
  taskStatus: TaskStatus,
};

export type WorkflowTriggerInput = {
  actionId: Scalars['String'],
  actionableType: TaskActionableType,
  taskStatus: TaskStatus,
};

export type GetMyIndividualQueryVariables = Exact<{ [key: string]: never }>;


export type GetMyIndividualQuery = { __typename?: 'Query', getMyIndividual?: { __typename?: 'Individual', id: string, createdAt: string, updatedAt: string, givenName: string, familyName: string, pictureURL: string, lastSyncedAt: string, teamConnections: Array<{ __typename?: 'IndividualTeamConnection', id: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType, companyNumber?: string | null } }> } | null };

export type DashboardContractorProfileFieldsFragment = { __typename?: 'ContractorProfile', id: string, companyNumber: string, companyRegisteredName: string, companyTradingAs?: string | null, _notesCount?: number | null, companyCheckStatusId?: string | null, statusId?: string | null, workHistory?: Array<{ __typename?: 'ContractorProfileWorkHistory', id: string, references?: Array<{ __typename?: 'ContractorProfileWorkHistoryReference', id: string, teamType: TeamType, companyName?: string | null, givenName: string, familyName: string, status: ProgressionStatus }> | null }> | null };

export type ListAllContractorProfilesQueryVariables = Exact<{
  filter?: InputMaybe<ListAllContractorProfilesFilter>,
}>;


export type ListAllContractorProfilesQuery = { __typename?: 'Query', listAllContractorProfiles: Array<{ __typename?: 'ContractorProfile', id: string, companyNumber: string, companyRegisteredName: string, companyTradingAs?: string | null, _notesCount?: number | null, companyCheckStatusId?: string | null, statusId?: string | null, workHistory?: Array<{ __typename?: 'ContractorProfileWorkHistory', id: string, references?: Array<{ __typename?: 'ContractorProfileWorkHistoryReference', id: string, teamType: TeamType, companyName?: string | null, givenName: string, familyName: string, status: ProgressionStatus }> | null }> | null }> };

export type RefreshContractorProfileQueryVariables = Exact<{
  contractorTeamId: Scalars['ID'],
}>;


export type RefreshContractorProfileQuery = { __typename?: 'Query', getContractorProfile?: { __typename?: 'ContractorProfile', id: string, companyNumber: string, companyRegisteredName: string, companyTradingAs?: string | null, _notesCount?: number | null, companyCheckStatusId?: string | null, statusId?: string | null, workHistory?: Array<{ __typename?: 'ContractorProfileWorkHistory', id: string, references?: Array<{ __typename?: 'ContractorProfileWorkHistoryReference', id: string, teamType: TeamType, companyName?: string | null, givenName: string, familyName: string, status: ProgressionStatus }> | null }> | null } | null };

export type ListContractorProfileNotesQueryVariables = Exact<{
  id: Scalars['ID'],
}>;


export type ListContractorProfileNotesQuery = { __typename?: 'Query', listContractorProfileNotes: Array<{ __typename?: 'ContractorProfileNote', id: string, body: string, createdAt: string, updatedAt: string, createdByIndividual: { __typename?: 'Individual', id: string, familyName: string, givenName: string, pictureURL: string } }> };

export type AddContractorProfileNoteMutationVariables = Exact<{
  parentId: Scalars['ID'],
  body: Scalars['String'],
}>;


export type AddContractorProfileNoteMutation = { __typename?: 'Mutation', addContractorProfileNote: boolean };

export type ListAllContractorCompanyCheckStatusQueryVariables = Exact<{ [key: string]: never }>;


export type ListAllContractorCompanyCheckStatusQuery = { __typename?: 'Query', listAllContractorCompanyCheckStatus: Array<{ __typename?: 'ContractorCompanyCheckStatus', id: string, label: string, progress: ContractorCompanyCheckProgress, color: string }> };

export type SelectContractorCompanyCheckStatusMutationVariables = Exact<{
  parentId: Scalars['ID'],
  statusId: Scalars['ID'],
}>;


export type SelectContractorCompanyCheckStatusMutation = { __typename?: 'Mutation', selectContractorCompanyCheckStatus: boolean };

export type UpdateContractorCompanyCheckStatusMutationVariables = Exact<{
  statusId: Scalars['ID'],
  label: Scalars['String'],
  color: Scalars['String'],
  progress: ContractorCompanyCheckProgress,
}>;


export type UpdateContractorCompanyCheckStatusMutation = { __typename?: 'Mutation', updateContractorCompanyCheckStatus: boolean };

export type AddContractorCompanyCheckStatusMutationVariables = Exact<{
  label: Scalars['String'],
  color: Scalars['String'],
  progress: ContractorCompanyCheckProgress,
}>;


export type AddContractorCompanyCheckStatusMutation = { __typename?: 'Mutation', addContractorCompanyCheckStatus: { __typename?: 'ContractorCompanyCheckStatus', id: string } };

export type ListAllContractorStatusQueryVariables = Exact<{ [key: string]: never }>;


export type ListAllContractorStatusQuery = { __typename?: 'Query', listAllContractorStatus: Array<{ __typename?: 'ContractorStatus', id: string, label: string, progress: ContractorProgress, color: string }> };

export type SelectContractorStatusMutationVariables = Exact<{
  parentId: Scalars['ID'],
  statusId: Scalars['ID'],
}>;


export type SelectContractorStatusMutation = { __typename?: 'Mutation', selectContractorStatus: boolean };

export type UpdateContractorStatusMutationVariables = Exact<{
  statusId: Scalars['ID'],
  label: Scalars['String'],
  color: Scalars['String'],
  progress: ContractorProgress,
}>;


export type UpdateContractorStatusMutation = { __typename?: 'Mutation', updateContractorStatus: boolean };

export type AddContractorStatusMutationVariables = Exact<{
  label: Scalars['String'],
  color: Scalars['String'],
  progress: ContractorProgress,
}>;


export type AddContractorStatusMutation = { __typename?: 'Mutation', addContractorStatus: { __typename?: 'ContractorStatus', id: string } };

export type ListBackgroundJobsQueryVariables = Exact<{ [key: string]: never }>;


export type ListBackgroundJobsQuery = { __typename?: 'Query', listBackgroundJobs: Array<{ __typename?: 'BackgroundJob', id: string, createdAt: string, updatedAt: string, startedAt?: string | null, finishedAt?: string | null, status: BackgroundJobStatus, type: BackgroundJobType, createdByIndividual: { __typename?: 'Individual', id: string, givenName: string, familyName: string }, payloadAsDataIntegrityCheckerReport?: { __typename?: 'BackgroundJobPayloadAsDataIntegrityCheckerReport', totalTableCount: number, successTableCount: number, failedTableCount: number, errors: Array<{ __typename?: 'BackgroundJobPayloadAsDataIntegrityCheckerReportTable', name: string, errorRowIds?: Array<string> | null }> } | null }> };

export type StartDataIntegrityCheckerReportMutationVariables = Exact<{ [key: string]: never }>;


export type StartDataIntegrityCheckerReportMutation = { __typename?: 'Mutation', startDataIntegrityCheckerReport: { __typename?: 'BackgroundJob', id: string, status: BackgroundJobStatus } };

export type GetMechaReleaseInfoQueryVariables = Exact<{ [key: string]: never }>;


export type GetMechaReleaseInfoQuery = { __typename?: 'Query', getReleaseInfo: { __typename?: 'ReleaseInfo', mechaVersion?: string | null, appFrontendVersion?: string | null, opsDashboardInfrastructureVersion?: string | null, opsDashboardFrontendVersion?: string | null } };

export type FlushCachesFrontendSpaMutationVariables = Exact<{ [key: string]: never }>;


export type FlushCachesFrontendSpaMutation = { __typename?: 'Mutation', flushCachesFrontendSPA: boolean };

export type GetStripeProductConfigsQueryVariables = Exact<{ [key: string]: never }>;


export type GetStripeProductConfigsQuery = { __typename?: 'Query', getStripeProductConfigs: Array<{ __typename?: 'StripeProductConfig', product: StripeProduct, productId: string, priceId: string, isRecurring: boolean, isLive: boolean, price: { __typename?: 'Money', currency: Currency, amountInPence: string } }> };

export type ClearStripeProductConfigCacheMutationVariables = Exact<{ [key: string]: never }>;


export type ClearStripeProductConfigCacheMutation = { __typename?: 'Mutation', clearStripeProductConfigCache: boolean };

export type ListAllTaskTemplatesQueryVariables = Exact<{ [key: string]: never }>;


export type ListAllTaskTemplatesQuery = { __typename?: 'Query', listAllTaskTemplates: Array<{ __typename?: 'TaskTemplate', id: string, trigger: TaskTemplateTrigger, updatedAt: string }> };

export type GetTaskTemplateQueryVariables = Exact<{
  id: Scalars['ID'],
}>;


export type GetTaskTemplateQuery = { __typename?: 'Query', getTaskTemplate?: { __typename?: 'TaskTemplate', id: string, trigger: TaskTemplateTrigger, body: string, updatedAt: string } | null };

export type CreateTaskTemplateMutationVariables = Exact<{
  input: CreateTaskTemplateInput,
}>;


export type CreateTaskTemplateMutation = { __typename?: 'Mutation', createTaskTemplate: { __typename?: 'TaskTemplate', id: string } };

export type UpdateTaskTemplateMutationVariables = Exact<{
  input: CreateTaskTemplateInput,
}>;


export type UpdateTaskTemplateMutation = { __typename?: 'Mutation', updateTaskTemplate: boolean };

export type DashboardProjectFieldsFragment = { __typename?: 'Project', assigneeTeamId?: string | null, id: string, lastActivity?: string | null, description: string, budgetCategory: string, status?: ProjectProgress | null, statusId?: string | null, _notesCount?: number | null, workStartEstimate: WorkStartEstimate, tenderReturnDate: string, note?: string | null, projectTypes: Array<ProjectType>, documentCount?: number | null, title: string, budgetValue: { __typename?: 'Money', currency: Currency, amountInPence: string }, memberSlots: Array<{ __typename?: 'ProjectMemberSlot', id: string, requiredProjectRole: ProjectMemberRole, requiredTeamType: TeamType, createdAt: string, updatedAt: string }>, memberInvites: Array<{ __typename?: 'ProjectMemberInvite', id: string, companyName?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, phone?: string | null, createdAt: string, updatedAt: string, team?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }>, members: Array<{ __typename?: 'ProjectMember', id: string, projectRole: ProjectMemberRole, createdAt: string, updatedAt: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } }>, quotes?: Array<{ __typename?: 'Quote', id: string }> | null, siteVisits?: Array<{ __typename?: 'SiteVisit', id: string }> | null, tasks: Array<{ __typename?: 'Task', id: string, status: TaskStatus, actionableType: TaskActionableType, title: string, order: number, activeStatus: TaskStatus }> };

export type ListAllProjectsQueryVariables = Exact<{
  filter?: InputMaybe<ListAllProjectsFilter>,
}>;


export type ListAllProjectsQuery = { __typename?: 'Query', listAllProjects: Array<{ __typename?: 'Project', assigneeTeamId?: string | null, id: string, lastActivity?: string | null, description: string, budgetCategory: string, status?: ProjectProgress | null, statusId?: string | null, _notesCount?: number | null, workStartEstimate: WorkStartEstimate, tenderReturnDate: string, note?: string | null, projectTypes: Array<ProjectType>, documentCount?: number | null, title: string, budgetValue: { __typename?: 'Money', currency: Currency, amountInPence: string }, memberSlots: Array<{ __typename?: 'ProjectMemberSlot', id: string, requiredProjectRole: ProjectMemberRole, requiredTeamType: TeamType, createdAt: string, updatedAt: string }>, memberInvites: Array<{ __typename?: 'ProjectMemberInvite', id: string, companyName?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, phone?: string | null, createdAt: string, updatedAt: string, team?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }>, members: Array<{ __typename?: 'ProjectMember', id: string, projectRole: ProjectMemberRole, createdAt: string, updatedAt: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } }>, quotes?: Array<{ __typename?: 'Quote', id: string }> | null, siteVisits?: Array<{ __typename?: 'SiteVisit', id: string }> | null, tasks: Array<{ __typename?: 'Task', id: string, status: TaskStatus, actionableType: TaskActionableType, title: string, order: number, activeStatus: TaskStatus }> }> };

export type RefreshSingleProjectQueryVariables = Exact<{
  projectId: Scalars['ID'],
}>;


export type RefreshSingleProjectQuery = { __typename?: 'Query', getProject: { __typename?: 'Project', assigneeTeamId?: string | null, id: string, lastActivity?: string | null, description: string, budgetCategory: string, status?: ProjectProgress | null, statusId?: string | null, _notesCount?: number | null, workStartEstimate: WorkStartEstimate, tenderReturnDate: string, note?: string | null, projectTypes: Array<ProjectType>, documentCount?: number | null, title: string, budgetValue: { __typename?: 'Money', currency: Currency, amountInPence: string }, memberSlots: Array<{ __typename?: 'ProjectMemberSlot', id: string, requiredProjectRole: ProjectMemberRole, requiredTeamType: TeamType, createdAt: string, updatedAt: string }>, memberInvites: Array<{ __typename?: 'ProjectMemberInvite', id: string, companyName?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, phone?: string | null, createdAt: string, updatedAt: string, team?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }>, members: Array<{ __typename?: 'ProjectMember', id: string, projectRole: ProjectMemberRole, createdAt: string, updatedAt: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } }>, quotes?: Array<{ __typename?: 'Quote', id: string }> | null, siteVisits?: Array<{ __typename?: 'SiteVisit', id: string }> | null, tasks: Array<{ __typename?: 'Task', id: string, status: TaskStatus, actionableType: TaskActionableType, title: string, order: number, activeStatus: TaskStatus }> } };

export type ListContractorSuggestionsForProjectQueryVariables = Exact<{
  projectId: Scalars['ID'],
}>;


export type ListContractorSuggestionsForProjectQuery = { __typename?: 'Query', listContractorSuggestionsForProject: Array<{ __typename?: 'ContractorProfile', id: string, companyRegisteredName: string, companyTradingAs?: string | null, acceptedLeads?: number | null, rejectedLeads?: number | null, companyNumber: string }> };

export type AddProjectSlotMutationVariables = Exact<{
  input: AddProjectSlotInput,
}>;


export type AddProjectSlotMutation = { __typename?: 'Mutation', addProjectSlot: boolean };

export type AddProjectInviteMutationVariables = Exact<{
  projectId: Scalars['ID'],
  teamId: Scalars['ID'],
  projectMemberRole: ProjectMemberRole,
}>;


export type AddProjectInviteMutation = { __typename?: 'Mutation', addProjectInvite: boolean };

export type RemoveProjectInviteMutationVariables = Exact<{
  id: Scalars['ID'],
}>;


export type RemoveProjectInviteMutation = { __typename?: 'Mutation', removeProjectInvite: boolean };

export type RemoveProjectMemberMutationVariables = Exact<{
  id: Scalars['ID'],
}>;


export type RemoveProjectMemberMutation = { __typename?: 'Mutation', removeProjectMember: boolean };

export type RemoveProjectSlotMutationVariables = Exact<{
  id: Scalars['ID'],
}>;


export type RemoveProjectSlotMutation = { __typename?: 'Mutation', removeProjectSlot: boolean };

export type ListProjectNotesQueryVariables = Exact<{
  id: Scalars['ID'],
}>;


export type ListProjectNotesQuery = { __typename?: 'Query', listProjectNotes: Array<{ __typename?: 'ProjectNote', id: string, body: string, createdAt: string, updatedAt: string, createdByIndividual: { __typename?: 'Individual', id: string, pictureURL: string, givenName: string, familyName: string } }> };

export type AddProjectNoteMutationVariables = Exact<{
  parentId: Scalars['ID'],
  body: Scalars['String'],
}>;


export type AddProjectNoteMutation = { __typename?: 'Mutation', addProjectNote: boolean };

export type CreateNewProjectStatusMutationVariables = Exact<{
  label: Scalars['String'],
  color: Scalars['String'],
  progress: ProjectProgress,
}>;


export type CreateNewProjectStatusMutation = { __typename?: 'Mutation', createNewProjectStatus: boolean };

export type UpdateProjectStatusMutationVariables = Exact<{
  statusId: Scalars['ID'],
  label: Scalars['String'],
  color: Scalars['String'],
  progress: ProjectProgress,
}>;


export type UpdateProjectStatusMutation = { __typename?: 'Mutation', updateProjectStatus: boolean };

export type SelectProjectStatusMutationVariables = Exact<{
  parentId: Scalars['ID'],
  statusId: Scalars['ID'],
}>;


export type SelectProjectStatusMutation = { __typename?: 'Mutation', selectProjectStatus: boolean };

export type ListAllProjectStatusQueryVariables = Exact<{ [key: string]: never }>;


export type ListAllProjectStatusQuery = { __typename?: 'Query', listAllProjectStatus: Array<{ __typename?: 'ProjectStatus', id: string, label: string, progress: ProjectProgress, color: string }> };

export type DashboardReferenceProfileFieldsFragment = { __typename?: 'OpsWorkHistoryReference', id: string, teamType: TeamType, companyName?: string | null, givenName: string, familyName: string, status: ProgressionStatus, contractorProfileWorkHistoryReferenceStatusId?: string | null, _notesCount?: number | null, contractorProfileWorkHistoryId: string, contractorProfileId: string, companyRegisteredName: string, companyTradingAs?: string | null, constructionValue?: { __typename?: 'Money', currency: Currency, amountInPence: string } | null };

export type ListAllOpsWorkHistoryReferencesQueryVariables = Exact<{
  filter?: InputMaybe<ListAllOpsWorkHistoryReferencesFilter>,
}>;


export type ListAllOpsWorkHistoryReferencesQuery = { __typename?: 'Query', listAllOpsWorkHistoryReferences: Array<{ __typename?: 'OpsWorkHistoryReference', id: string, teamType: TeamType, companyName?: string | null, givenName: string, familyName: string, status: ProgressionStatus, contractorProfileWorkHistoryReferenceStatusId?: string | null, _notesCount?: number | null, contractorProfileWorkHistoryId: string, contractorProfileId: string, companyRegisteredName: string, companyTradingAs?: string | null, constructionValue?: { __typename?: 'Money', currency: Currency, amountInPence: string } | null }> };

export type ListAllContractorProfileWorkHistoryReferenceNotesQueryVariables = Exact<{
  id: Scalars['ID'],
}>;


export type ListAllContractorProfileWorkHistoryReferenceNotesQuery = { __typename?: 'Query', listAllContractorProfileWorkHistoryReferenceNotes: Array<{ __typename?: 'ContractorProfileWorkHistoryReferenceNote', id: string, body: string, createdAt: string, updatedAt: string, createdByIndividual: { __typename?: 'Individual', id: string, familyName: string, givenName: string, pictureURL: string } }> };

export type AddContractorProfileWorkHistoryReferenceNoteMutationVariables = Exact<{
  parentId: Scalars['ID'],
  body: Scalars['String'],
}>;


export type AddContractorProfileWorkHistoryReferenceNoteMutation = { __typename?: 'Mutation', addContractorProfileWorkHistoryReferenceNote: boolean };

export type ListAllContractorProfileWorkHistoryReferenceStatusesQueryVariables = Exact<{ [key: string]: never }>;


export type ListAllContractorProfileWorkHistoryReferenceStatusesQuery = { __typename?: 'Query', listAllContractorProfileWorkHistoryReferenceStatuses: Array<{ __typename?: 'ContractorProfileWorkHistoryReferenceStatus', id: string, label: string, progress: ProgressionStatus, color: string }> };

export type SelectContractorProfileWorkHistoryReferenceStatusMutationVariables = Exact<{
  parentId: Scalars['ID'],
  statusId: Scalars['ID'],
}>;


export type SelectContractorProfileWorkHistoryReferenceStatusMutation = { __typename?: 'Mutation', selectContractorProfileWorkHistoryReferenceStatus: boolean };

export type UpdateContractorProfileWorkHistoryReferenceStatusMutationVariables = Exact<{
  statusId: Scalars['ID'],
  label: Scalars['String'],
  color: Scalars['String'],
  progress: ProgressionStatus,
}>;


export type UpdateContractorProfileWorkHistoryReferenceStatusMutation = { __typename?: 'Mutation', updateContractorProfileWorkHistoryReferenceStatus: boolean };

export type AddContractorProfileWorkHistoryReferenceStatusMutationVariables = Exact<{
  label: Scalars['String'],
  color: Scalars['String'],
  progress: ProgressionStatus,
}>;


export type AddContractorProfileWorkHistoryReferenceStatusMutation = { __typename?: 'Mutation', addContractorProfileWorkHistoryReferenceStatus: { __typename?: 'ContractorProfileWorkHistoryReferenceStatus', id: string } };

export const DashboardContractorProfileFieldsFragmentDoc = `
    fragment DashboardContractorProfileFields on ContractorProfile {
  id
  companyNumber
  companyRegisteredName
  companyTradingAs
  _notesCount
  workHistory {
    id
    references {
      id
      teamType
      companyName
      givenName
      familyName
      status
    }
  }
  companyCheckStatusId
  statusId
}
    `
export const DashboardProjectFieldsFragmentDoc = `
    fragment DashboardProjectFields on Project {
  assigneeTeamId
  id
  lastActivity
  description
  budgetCategory
  budgetValue {
    currency
    amountInPence
  }
  status
  statusId
  _notesCount
  workStartEstimate
  tenderReturnDate
  memberSlots {
    id
    requiredProjectRole
    requiredTeamType
    createdAt
    updatedAt
  }
  memberInvites(ignoreScope: true) {
    id
    team {
      id
      name
      type
    }
    companyName
    givenName
    familyName
    email
    phone
    createdAt
    updatedAt
  }
  members(ignoreScope: true) {
    id
    team {
      id
      name
      type
    }
    projectRole
    createdAt
    updatedAt
  }
  note
  projectTypes
  quotes {
    id
  }
  siteVisits {
    id
  }
  tasks(ignoreScope: true) {
    id
    status
    actionableType
    title
    order
    activeStatus
  }
  documentCount
  title
}
    `
export const DashboardReferenceProfileFieldsFragmentDoc = `
    fragment DashboardReferenceProfileFields on OpsWorkHistoryReference {
  id
  teamType
  companyName
  givenName
  familyName
  status
  contractorProfileWorkHistoryReferenceStatusId
  _notesCount
  contractorProfileWorkHistoryId
  constructionValue {
    currency
    amountInPence
  }
  contractorProfileId
  companyRegisteredName
  companyTradingAs
}
    `
export const GetMyIndividualDocument = `
    query getMyIndividual {
  getMyIndividual {
    id
    createdAt
    updatedAt
    givenName
    familyName
    pictureURL
    lastSyncedAt
    teamConnections {
      id
      team {
        id
        name
        type
        companyNumber
      }
    }
  }
}
    `
export const useGetMyIndividualQuery = <
  TData = GetMyIndividualQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: GetMyIndividualQueryVariables,
  options?: UseQueryOptions<GetMyIndividualQuery, TError, TData>,
) =>
  useQuery<GetMyIndividualQuery, TError, TData>(
    variables === undefined ? [ 'getMyIndividual' ] : [ 'getMyIndividual', variables ],
    fetcher<GetMyIndividualQuery, GetMyIndividualQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetMyIndividualDocument, variables),
    options,
  )
useGetMyIndividualQuery.document = GetMyIndividualDocument


useGetMyIndividualQuery.getKey = (variables?: GetMyIndividualQueryVariables) => variables === undefined ? [ 'getMyIndividual' ] : [ 'getMyIndividual', variables ]


useGetMyIndividualQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: GetMyIndividualQueryVariables) => fetcher<GetMyIndividualQuery, GetMyIndividualQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetMyIndividualDocument, variables)
export const ListAllContractorProfilesDocument = `
    query listAllContractorProfiles($filter: ListAllContractorProfilesFilter) {
  listAllContractorProfiles(filter: $filter) {
    ...DashboardContractorProfileFields
  }
}
    ${DashboardContractorProfileFieldsFragmentDoc}`
export const useListAllContractorProfilesQuery = <
  TData = ListAllContractorProfilesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllContractorProfilesQueryVariables,
  options?: UseQueryOptions<ListAllContractorProfilesQuery, TError, TData>,
) =>
  useQuery<ListAllContractorProfilesQuery, TError, TData>(
    variables === undefined ? [ 'listAllContractorProfiles' ] : [ 'listAllContractorProfiles', variables ],
    fetcher<ListAllContractorProfilesQuery, ListAllContractorProfilesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorProfilesDocument, variables),
    options,
  )
useListAllContractorProfilesQuery.document = ListAllContractorProfilesDocument


useListAllContractorProfilesQuery.getKey = (variables?: ListAllContractorProfilesQueryVariables) => variables === undefined ? [ 'listAllContractorProfiles' ] : [ 'listAllContractorProfiles', variables ]


useListAllContractorProfilesQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListAllContractorProfilesQueryVariables) => fetcher<ListAllContractorProfilesQuery, ListAllContractorProfilesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorProfilesDocument, variables)
export const RefreshContractorProfileDocument = `
    query refreshContractorProfile($contractorTeamId: ID!) {
  getContractorProfile(contractorTeamId: $contractorTeamId) {
    ...DashboardContractorProfileFields
  }
}
    ${DashboardContractorProfileFieldsFragmentDoc}`
export const useRefreshContractorProfileQuery = <
  TData = RefreshContractorProfileQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: RefreshContractorProfileQueryVariables,
  options?: UseQueryOptions<RefreshContractorProfileQuery, TError, TData>,
) =>
  useQuery<RefreshContractorProfileQuery, TError, TData>(
    [ 'refreshContractorProfile', variables ],
    fetcher<RefreshContractorProfileQuery, RefreshContractorProfileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RefreshContractorProfileDocument, variables),
    options,
  )
useRefreshContractorProfileQuery.document = RefreshContractorProfileDocument


useRefreshContractorProfileQuery.getKey = (variables: RefreshContractorProfileQueryVariables) => [ 'refreshContractorProfile', variables ]


useRefreshContractorProfileQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: RefreshContractorProfileQueryVariables) => fetcher<RefreshContractorProfileQuery, RefreshContractorProfileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RefreshContractorProfileDocument, variables)
export const ListContractorProfileNotesDocument = `
    query listContractorProfileNotes($id: ID!) {
  listContractorProfileNotes(contractorProfileId: $id) {
    id
    body
    createdAt
    updatedAt
    createdByIndividual {
      id
      familyName
      givenName
      pictureURL
    }
  }
}
    `
export const useListContractorProfileNotesQuery = <
  TData = ListContractorProfileNotesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ListContractorProfileNotesQueryVariables,
  options?: UseQueryOptions<ListContractorProfileNotesQuery, TError, TData>,
) =>
  useQuery<ListContractorProfileNotesQuery, TError, TData>(
    [ 'listContractorProfileNotes', variables ],
    fetcher<ListContractorProfileNotesQuery, ListContractorProfileNotesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListContractorProfileNotesDocument, variables),
    options,
  )
useListContractorProfileNotesQuery.document = ListContractorProfileNotesDocument


useListContractorProfileNotesQuery.getKey = (variables: ListContractorProfileNotesQueryVariables) => [ 'listContractorProfileNotes', variables ]


useListContractorProfileNotesQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: ListContractorProfileNotesQueryVariables) => fetcher<ListContractorProfileNotesQuery, ListContractorProfileNotesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListContractorProfileNotesDocument, variables)
export const AddContractorProfileNoteDocument = `
    mutation addContractorProfileNote($parentId: ID!, $body: String!) {
  addContractorProfileNote(contractorProfileId: $parentId, body: $body)
}
    `
export const useAddContractorProfileNoteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddContractorProfileNoteMutation, TError, AddContractorProfileNoteMutationVariables, TContext>,
) =>
  useMutation<AddContractorProfileNoteMutation, TError, AddContractorProfileNoteMutationVariables, TContext>(
    [ 'addContractorProfileNote' ],
    (variables?: AddContractorProfileNoteMutationVariables) => fetcher<AddContractorProfileNoteMutation, AddContractorProfileNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorProfileNoteDocument, variables)(),
    options,
  )
useAddContractorProfileNoteMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddContractorProfileNoteMutationVariables) => fetcher<AddContractorProfileNoteMutation, AddContractorProfileNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorProfileNoteDocument, variables)
export const ListAllContractorCompanyCheckStatusDocument = `
    query listAllContractorCompanyCheckStatus {
  listAllContractorCompanyCheckStatus {
    id
    label
    progress
    color
  }
}
    `
export const useListAllContractorCompanyCheckStatusQuery = <
  TData = ListAllContractorCompanyCheckStatusQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllContractorCompanyCheckStatusQueryVariables,
  options?: UseQueryOptions<ListAllContractorCompanyCheckStatusQuery, TError, TData>,
) =>
  useQuery<ListAllContractorCompanyCheckStatusQuery, TError, TData>(
    variables === undefined ? [ 'listAllContractorCompanyCheckStatus' ] : [ 'listAllContractorCompanyCheckStatus', variables ],
    fetcher<ListAllContractorCompanyCheckStatusQuery, ListAllContractorCompanyCheckStatusQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorCompanyCheckStatusDocument, variables),
    options,
  )
useListAllContractorCompanyCheckStatusQuery.document = ListAllContractorCompanyCheckStatusDocument


useListAllContractorCompanyCheckStatusQuery.getKey = (variables?: ListAllContractorCompanyCheckStatusQueryVariables) => variables === undefined ? [ 'listAllContractorCompanyCheckStatus' ] : [ 'listAllContractorCompanyCheckStatus', variables ]


useListAllContractorCompanyCheckStatusQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListAllContractorCompanyCheckStatusQueryVariables) => fetcher<ListAllContractorCompanyCheckStatusQuery, ListAllContractorCompanyCheckStatusQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorCompanyCheckStatusDocument, variables)
export const SelectContractorCompanyCheckStatusDocument = `
    mutation selectContractorCompanyCheckStatus($parentId: ID!, $statusId: ID!) {
  selectContractorCompanyCheckStatus(
    contractorProfileId: $parentId
    contractorCompanyCheckStatusId: $statusId
  )
}
    `
export const useSelectContractorCompanyCheckStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<SelectContractorCompanyCheckStatusMutation, TError, SelectContractorCompanyCheckStatusMutationVariables, TContext>,
) =>
  useMutation<SelectContractorCompanyCheckStatusMutation, TError, SelectContractorCompanyCheckStatusMutationVariables, TContext>(
    [ 'selectContractorCompanyCheckStatus' ],
    (variables?: SelectContractorCompanyCheckStatusMutationVariables) => fetcher<SelectContractorCompanyCheckStatusMutation, SelectContractorCompanyCheckStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SelectContractorCompanyCheckStatusDocument, variables)(),
    options,
  )
useSelectContractorCompanyCheckStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: SelectContractorCompanyCheckStatusMutationVariables) => fetcher<SelectContractorCompanyCheckStatusMutation, SelectContractorCompanyCheckStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SelectContractorCompanyCheckStatusDocument, variables)
export const UpdateContractorCompanyCheckStatusDocument = `
    mutation updateContractorCompanyCheckStatus($statusId: ID!, $label: String!, $color: String!, $progress: ContractorCompanyCheckProgress!) {
  updateContractorCompanyCheckStatus(
    contractorCompanyCheckStatusId: $statusId
    label: $label
    color: $color
    progress: $progress
  )
}
    `
export const useUpdateContractorCompanyCheckStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateContractorCompanyCheckStatusMutation, TError, UpdateContractorCompanyCheckStatusMutationVariables, TContext>,
) =>
  useMutation<UpdateContractorCompanyCheckStatusMutation, TError, UpdateContractorCompanyCheckStatusMutationVariables, TContext>(
    [ 'updateContractorCompanyCheckStatus' ],
    (variables?: UpdateContractorCompanyCheckStatusMutationVariables) => fetcher<UpdateContractorCompanyCheckStatusMutation, UpdateContractorCompanyCheckStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateContractorCompanyCheckStatusDocument, variables)(),
    options,
  )
useUpdateContractorCompanyCheckStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: UpdateContractorCompanyCheckStatusMutationVariables) => fetcher<UpdateContractorCompanyCheckStatusMutation, UpdateContractorCompanyCheckStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateContractorCompanyCheckStatusDocument, variables)
export const AddContractorCompanyCheckStatusDocument = `
    mutation addContractorCompanyCheckStatus($label: String!, $color: String!, $progress: ContractorCompanyCheckProgress!) {
  addContractorCompanyCheckStatus(
    label: $label
    color: $color
    progress: $progress
  ) {
    id
  }
}
    `
export const useAddContractorCompanyCheckStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddContractorCompanyCheckStatusMutation, TError, AddContractorCompanyCheckStatusMutationVariables, TContext>,
) =>
  useMutation<AddContractorCompanyCheckStatusMutation, TError, AddContractorCompanyCheckStatusMutationVariables, TContext>(
    [ 'addContractorCompanyCheckStatus' ],
    (variables?: AddContractorCompanyCheckStatusMutationVariables) => fetcher<AddContractorCompanyCheckStatusMutation, AddContractorCompanyCheckStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorCompanyCheckStatusDocument, variables)(),
    options,
  )
useAddContractorCompanyCheckStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddContractorCompanyCheckStatusMutationVariables) => fetcher<AddContractorCompanyCheckStatusMutation, AddContractorCompanyCheckStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorCompanyCheckStatusDocument, variables)
export const ListAllContractorStatusDocument = `
    query listAllContractorStatus {
  listAllContractorStatus {
    id
    label
    progress
    color
  }
}
    `
export const useListAllContractorStatusQuery = <
  TData = ListAllContractorStatusQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllContractorStatusQueryVariables,
  options?: UseQueryOptions<ListAllContractorStatusQuery, TError, TData>,
) =>
  useQuery<ListAllContractorStatusQuery, TError, TData>(
    variables === undefined ? [ 'listAllContractorStatus' ] : [ 'listAllContractorStatus', variables ],
    fetcher<ListAllContractorStatusQuery, ListAllContractorStatusQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorStatusDocument, variables),
    options,
  )
useListAllContractorStatusQuery.document = ListAllContractorStatusDocument


useListAllContractorStatusQuery.getKey = (variables?: ListAllContractorStatusQueryVariables) => variables === undefined ? [ 'listAllContractorStatus' ] : [ 'listAllContractorStatus', variables ]


useListAllContractorStatusQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListAllContractorStatusQueryVariables) => fetcher<ListAllContractorStatusQuery, ListAllContractorStatusQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorStatusDocument, variables)
export const SelectContractorStatusDocument = `
    mutation selectContractorStatus($parentId: ID!, $statusId: ID!) {
  selectContractorStatus(
    contractorProfileId: $parentId
    contractorStatusId: $statusId
  )
}
    `
export const useSelectContractorStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<SelectContractorStatusMutation, TError, SelectContractorStatusMutationVariables, TContext>,
) =>
  useMutation<SelectContractorStatusMutation, TError, SelectContractorStatusMutationVariables, TContext>(
    [ 'selectContractorStatus' ],
    (variables?: SelectContractorStatusMutationVariables) => fetcher<SelectContractorStatusMutation, SelectContractorStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SelectContractorStatusDocument, variables)(),
    options,
  )
useSelectContractorStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: SelectContractorStatusMutationVariables) => fetcher<SelectContractorStatusMutation, SelectContractorStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SelectContractorStatusDocument, variables)
export const UpdateContractorStatusDocument = `
    mutation updateContractorStatus($statusId: ID!, $label: String!, $color: String!, $progress: ContractorProgress!) {
  updateContractorStatus(
    contractorStatusId: $statusId
    label: $label
    color: $color
    progress: $progress
  )
}
    `
export const useUpdateContractorStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateContractorStatusMutation, TError, UpdateContractorStatusMutationVariables, TContext>,
) =>
  useMutation<UpdateContractorStatusMutation, TError, UpdateContractorStatusMutationVariables, TContext>(
    [ 'updateContractorStatus' ],
    (variables?: UpdateContractorStatusMutationVariables) => fetcher<UpdateContractorStatusMutation, UpdateContractorStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateContractorStatusDocument, variables)(),
    options,
  )
useUpdateContractorStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: UpdateContractorStatusMutationVariables) => fetcher<UpdateContractorStatusMutation, UpdateContractorStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateContractorStatusDocument, variables)
export const AddContractorStatusDocument = `
    mutation addContractorStatus($label: String!, $color: String!, $progress: ContractorProgress!) {
  addContractorStatus(label: $label, color: $color, progress: $progress) {
    id
  }
}
    `
export const useAddContractorStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddContractorStatusMutation, TError, AddContractorStatusMutationVariables, TContext>,
) =>
  useMutation<AddContractorStatusMutation, TError, AddContractorStatusMutationVariables, TContext>(
    [ 'addContractorStatus' ],
    (variables?: AddContractorStatusMutationVariables) => fetcher<AddContractorStatusMutation, AddContractorStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorStatusDocument, variables)(),
    options,
  )
useAddContractorStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddContractorStatusMutationVariables) => fetcher<AddContractorStatusMutation, AddContractorStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorStatusDocument, variables)
export const ListBackgroundJobsDocument = `
    query listBackgroundJobs {
  listBackgroundJobs {
    id
    createdAt
    createdByIndividual {
      id
      givenName
      familyName
    }
    updatedAt
    startedAt
    finishedAt
    status
    type
    payloadAsDataIntegrityCheckerReport {
      totalTableCount
      successTableCount
      failedTableCount
      errors {
        name
        errorRowIds
      }
    }
  }
}
    `
export const useListBackgroundJobsQuery = <
  TData = ListBackgroundJobsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListBackgroundJobsQueryVariables,
  options?: UseQueryOptions<ListBackgroundJobsQuery, TError, TData>,
) =>
  useQuery<ListBackgroundJobsQuery, TError, TData>(
    variables === undefined ? [ 'listBackgroundJobs' ] : [ 'listBackgroundJobs', variables ],
    fetcher<ListBackgroundJobsQuery, ListBackgroundJobsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListBackgroundJobsDocument, variables),
    options,
  )
useListBackgroundJobsQuery.document = ListBackgroundJobsDocument


useListBackgroundJobsQuery.getKey = (variables?: ListBackgroundJobsQueryVariables) => variables === undefined ? [ 'listBackgroundJobs' ] : [ 'listBackgroundJobs', variables ]


useListBackgroundJobsQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListBackgroundJobsQueryVariables) => fetcher<ListBackgroundJobsQuery, ListBackgroundJobsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListBackgroundJobsDocument, variables)
export const StartDataIntegrityCheckerReportDocument = `
    mutation startDataIntegrityCheckerReport {
  startDataIntegrityCheckerReport {
    id
    status
  }
}
    `
export const useStartDataIntegrityCheckerReportMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<StartDataIntegrityCheckerReportMutation, TError, StartDataIntegrityCheckerReportMutationVariables, TContext>,
) =>
  useMutation<StartDataIntegrityCheckerReportMutation, TError, StartDataIntegrityCheckerReportMutationVariables, TContext>(
    [ 'startDataIntegrityCheckerReport' ],
    (variables?: StartDataIntegrityCheckerReportMutationVariables) => fetcher<StartDataIntegrityCheckerReportMutation, StartDataIntegrityCheckerReportMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, StartDataIntegrityCheckerReportDocument, variables)(),
    options,
  )
useStartDataIntegrityCheckerReportMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: StartDataIntegrityCheckerReportMutationVariables) => fetcher<StartDataIntegrityCheckerReportMutation, StartDataIntegrityCheckerReportMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, StartDataIntegrityCheckerReportDocument, variables)
export const GetMechaReleaseInfoDocument = `
    query getMechaReleaseInfo {
  getReleaseInfo {
    mechaVersion
    appFrontendVersion
    opsDashboardInfrastructureVersion
    opsDashboardFrontendVersion
  }
}
    `
export const useGetMechaReleaseInfoQuery = <
  TData = GetMechaReleaseInfoQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: GetMechaReleaseInfoQueryVariables,
  options?: UseQueryOptions<GetMechaReleaseInfoQuery, TError, TData>,
) =>
  useQuery<GetMechaReleaseInfoQuery, TError, TData>(
    variables === undefined ? [ 'getMechaReleaseInfo' ] : [ 'getMechaReleaseInfo', variables ],
    fetcher<GetMechaReleaseInfoQuery, GetMechaReleaseInfoQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetMechaReleaseInfoDocument, variables),
    options,
  )
useGetMechaReleaseInfoQuery.document = GetMechaReleaseInfoDocument


useGetMechaReleaseInfoQuery.getKey = (variables?: GetMechaReleaseInfoQueryVariables) => variables === undefined ? [ 'getMechaReleaseInfo' ] : [ 'getMechaReleaseInfo', variables ]


useGetMechaReleaseInfoQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: GetMechaReleaseInfoQueryVariables) => fetcher<GetMechaReleaseInfoQuery, GetMechaReleaseInfoQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetMechaReleaseInfoDocument, variables)
export const FlushCachesFrontendSpaDocument = `
    mutation flushCachesFrontendSPA {
  flushCachesFrontendSPA
}
    `
export const useFlushCachesFrontendSpaMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<FlushCachesFrontendSpaMutation, TError, FlushCachesFrontendSpaMutationVariables, TContext>,
) =>
  useMutation<FlushCachesFrontendSpaMutation, TError, FlushCachesFrontendSpaMutationVariables, TContext>(
    [ 'flushCachesFrontendSPA' ],
    (variables?: FlushCachesFrontendSpaMutationVariables) => fetcher<FlushCachesFrontendSpaMutation, FlushCachesFrontendSpaMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, FlushCachesFrontendSpaDocument, variables)(),
    options,
  )
useFlushCachesFrontendSpaMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: FlushCachesFrontendSpaMutationVariables) => fetcher<FlushCachesFrontendSpaMutation, FlushCachesFrontendSpaMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, FlushCachesFrontendSpaDocument, variables)
export const GetStripeProductConfigsDocument = `
    query getStripeProductConfigs {
  getStripeProductConfigs {
    product
    productId
    priceId
    price {
      currency
      amountInPence
    }
    isRecurring
    isLive
  }
}
    `
export const useGetStripeProductConfigsQuery = <
  TData = GetStripeProductConfigsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: GetStripeProductConfigsQueryVariables,
  options?: UseQueryOptions<GetStripeProductConfigsQuery, TError, TData>,
) =>
  useQuery<GetStripeProductConfigsQuery, TError, TData>(
    variables === undefined ? [ 'getStripeProductConfigs' ] : [ 'getStripeProductConfigs', variables ],
    fetcher<GetStripeProductConfigsQuery, GetStripeProductConfigsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetStripeProductConfigsDocument, variables),
    options,
  )
useGetStripeProductConfigsQuery.document = GetStripeProductConfigsDocument


useGetStripeProductConfigsQuery.getKey = (variables?: GetStripeProductConfigsQueryVariables) => variables === undefined ? [ 'getStripeProductConfigs' ] : [ 'getStripeProductConfigs', variables ]


useGetStripeProductConfigsQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: GetStripeProductConfigsQueryVariables) => fetcher<GetStripeProductConfigsQuery, GetStripeProductConfigsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetStripeProductConfigsDocument, variables)
export const ClearStripeProductConfigCacheDocument = `
    mutation clearStripeProductConfigCache {
  clearStripeProductConfigCache
}
    `
export const useClearStripeProductConfigCacheMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<ClearStripeProductConfigCacheMutation, TError, ClearStripeProductConfigCacheMutationVariables, TContext>,
) =>
  useMutation<ClearStripeProductConfigCacheMutation, TError, ClearStripeProductConfigCacheMutationVariables, TContext>(
    [ 'clearStripeProductConfigCache' ],
    (variables?: ClearStripeProductConfigCacheMutationVariables) => fetcher<ClearStripeProductConfigCacheMutation, ClearStripeProductConfigCacheMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ClearStripeProductConfigCacheDocument, variables)(),
    options,
  )
useClearStripeProductConfigCacheMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ClearStripeProductConfigCacheMutationVariables) => fetcher<ClearStripeProductConfigCacheMutation, ClearStripeProductConfigCacheMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ClearStripeProductConfigCacheDocument, variables)
export const ListAllTaskTemplatesDocument = `
    query listAllTaskTemplates {
  listAllTaskTemplates {
    id
    trigger
    updatedAt
  }
}
    `
export const useListAllTaskTemplatesQuery = <
  TData = ListAllTaskTemplatesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllTaskTemplatesQueryVariables,
  options?: UseQueryOptions<ListAllTaskTemplatesQuery, TError, TData>,
) =>
  useQuery<ListAllTaskTemplatesQuery, TError, TData>(
    variables === undefined ? [ 'listAllTaskTemplates' ] : [ 'listAllTaskTemplates', variables ],
    fetcher<ListAllTaskTemplatesQuery, ListAllTaskTemplatesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllTaskTemplatesDocument, variables),
    options,
  )
useListAllTaskTemplatesQuery.document = ListAllTaskTemplatesDocument


useListAllTaskTemplatesQuery.getKey = (variables?: ListAllTaskTemplatesQueryVariables) => variables === undefined ? [ 'listAllTaskTemplates' ] : [ 'listAllTaskTemplates', variables ]


useListAllTaskTemplatesQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListAllTaskTemplatesQueryVariables) => fetcher<ListAllTaskTemplatesQuery, ListAllTaskTemplatesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllTaskTemplatesDocument, variables)
export const GetTaskTemplateDocument = `
    query getTaskTemplate($id: ID!) {
  getTaskTemplate(id: $id) {
    id
    trigger
    body
    updatedAt
  }
}
    `
export const useGetTaskTemplateQuery = <
  TData = GetTaskTemplateQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetTaskTemplateQueryVariables,
  options?: UseQueryOptions<GetTaskTemplateQuery, TError, TData>,
) =>
  useQuery<GetTaskTemplateQuery, TError, TData>(
    [ 'getTaskTemplate', variables ],
    fetcher<GetTaskTemplateQuery, GetTaskTemplateQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetTaskTemplateDocument, variables),
    options,
  )
useGetTaskTemplateQuery.document = GetTaskTemplateDocument


useGetTaskTemplateQuery.getKey = (variables: GetTaskTemplateQueryVariables) => [ 'getTaskTemplate', variables ]


useGetTaskTemplateQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: GetTaskTemplateQueryVariables) => fetcher<GetTaskTemplateQuery, GetTaskTemplateQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetTaskTemplateDocument, variables)
export const CreateTaskTemplateDocument = `
    mutation createTaskTemplate($input: CreateTaskTemplateInput!) {
  createTaskTemplate(input: $input) {
    id
  }
}
    `
export const useCreateTaskTemplateMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateTaskTemplateMutation, TError, CreateTaskTemplateMutationVariables, TContext>,
) =>
  useMutation<CreateTaskTemplateMutation, TError, CreateTaskTemplateMutationVariables, TContext>(
    [ 'createTaskTemplate' ],
    (variables?: CreateTaskTemplateMutationVariables) => fetcher<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateTaskTemplateDocument, variables)(),
    options,
  )
useCreateTaskTemplateMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: CreateTaskTemplateMutationVariables) => fetcher<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateTaskTemplateDocument, variables)
export const UpdateTaskTemplateDocument = `
    mutation updateTaskTemplate($input: CreateTaskTemplateInput!) {
  updateTaskTemplate(input: $input)
}
    `
export const useUpdateTaskTemplateMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateTaskTemplateMutation, TError, UpdateTaskTemplateMutationVariables, TContext>,
) =>
  useMutation<UpdateTaskTemplateMutation, TError, UpdateTaskTemplateMutationVariables, TContext>(
    [ 'updateTaskTemplate' ],
    (variables?: UpdateTaskTemplateMutationVariables) => fetcher<UpdateTaskTemplateMutation, UpdateTaskTemplateMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateTaskTemplateDocument, variables)(),
    options,
  )
useUpdateTaskTemplateMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: UpdateTaskTemplateMutationVariables) => fetcher<UpdateTaskTemplateMutation, UpdateTaskTemplateMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateTaskTemplateDocument, variables)
export const ListAllProjectsDocument = `
    query listAllProjects($filter: ListAllProjectsFilter) {
  listAllProjects(filter: $filter) {
    ...DashboardProjectFields
  }
}
    ${DashboardProjectFieldsFragmentDoc}`
export const useListAllProjectsQuery = <
  TData = ListAllProjectsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllProjectsQueryVariables,
  options?: UseQueryOptions<ListAllProjectsQuery, TError, TData>,
) =>
  useQuery<ListAllProjectsQuery, TError, TData>(
    variables === undefined ? [ 'listAllProjects' ] : [ 'listAllProjects', variables ],
    fetcher<ListAllProjectsQuery, ListAllProjectsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllProjectsDocument, variables),
    options,
  )
useListAllProjectsQuery.document = ListAllProjectsDocument


useListAllProjectsQuery.getKey = (variables?: ListAllProjectsQueryVariables) => variables === undefined ? [ 'listAllProjects' ] : [ 'listAllProjects', variables ]


useListAllProjectsQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListAllProjectsQueryVariables) => fetcher<ListAllProjectsQuery, ListAllProjectsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllProjectsDocument, variables)
export const RefreshSingleProjectDocument = `
    query refreshSingleProject($projectId: ID!) {
  getProject(id: $projectId, ignoreScope: true) {
    ...DashboardProjectFields
  }
}
    ${DashboardProjectFieldsFragmentDoc}`
export const useRefreshSingleProjectQuery = <
  TData = RefreshSingleProjectQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: RefreshSingleProjectQueryVariables,
  options?: UseQueryOptions<RefreshSingleProjectQuery, TError, TData>,
) =>
  useQuery<RefreshSingleProjectQuery, TError, TData>(
    [ 'refreshSingleProject', variables ],
    fetcher<RefreshSingleProjectQuery, RefreshSingleProjectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RefreshSingleProjectDocument, variables),
    options,
  )
useRefreshSingleProjectQuery.document = RefreshSingleProjectDocument


useRefreshSingleProjectQuery.getKey = (variables: RefreshSingleProjectQueryVariables) => [ 'refreshSingleProject', variables ]


useRefreshSingleProjectQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: RefreshSingleProjectQueryVariables) => fetcher<RefreshSingleProjectQuery, RefreshSingleProjectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RefreshSingleProjectDocument, variables)
export const ListContractorSuggestionsForProjectDocument = `
    query listContractorSuggestionsForProject($projectId: ID!) {
  listContractorSuggestionsForProject(projectId: $projectId) {
    id
    companyRegisteredName
    companyTradingAs
    acceptedLeads
    rejectedLeads
    companyTradingAs
    companyNumber
  }
}
    `
export const useListContractorSuggestionsForProjectQuery = <
  TData = ListContractorSuggestionsForProjectQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ListContractorSuggestionsForProjectQueryVariables,
  options?: UseQueryOptions<ListContractorSuggestionsForProjectQuery, TError, TData>,
) =>
  useQuery<ListContractorSuggestionsForProjectQuery, TError, TData>(
    [ 'listContractorSuggestionsForProject', variables ],
    fetcher<ListContractorSuggestionsForProjectQuery, ListContractorSuggestionsForProjectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListContractorSuggestionsForProjectDocument, variables),
    options,
  )
useListContractorSuggestionsForProjectQuery.document = ListContractorSuggestionsForProjectDocument


useListContractorSuggestionsForProjectQuery.getKey = (variables: ListContractorSuggestionsForProjectQueryVariables) => [ 'listContractorSuggestionsForProject', variables ]


useListContractorSuggestionsForProjectQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: ListContractorSuggestionsForProjectQueryVariables) => fetcher<ListContractorSuggestionsForProjectQuery, ListContractorSuggestionsForProjectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListContractorSuggestionsForProjectDocument, variables)
export const AddProjectSlotDocument = `
    mutation addProjectSlot($input: AddProjectSlotInput!) {
  addProjectSlot(input: $input)
}
    `
export const useAddProjectSlotMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddProjectSlotMutation, TError, AddProjectSlotMutationVariables, TContext>,
) =>
  useMutation<AddProjectSlotMutation, TError, AddProjectSlotMutationVariables, TContext>(
    [ 'addProjectSlot' ],
    (variables?: AddProjectSlotMutationVariables) => fetcher<AddProjectSlotMutation, AddProjectSlotMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectSlotDocument, variables)(),
    options,
  )
useAddProjectSlotMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddProjectSlotMutationVariables) => fetcher<AddProjectSlotMutation, AddProjectSlotMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectSlotDocument, variables)
export const AddProjectInviteDocument = `
    mutation addProjectInvite($projectId: ID!, $teamId: ID!, $projectMemberRole: ProjectMemberRole!) {
  addProjectInvite(
    projectId: $projectId
    teamId: $teamId
    projectMemberRole: $projectMemberRole
  )
}
    `
export const useAddProjectInviteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddProjectInviteMutation, TError, AddProjectInviteMutationVariables, TContext>,
) =>
  useMutation<AddProjectInviteMutation, TError, AddProjectInviteMutationVariables, TContext>(
    [ 'addProjectInvite' ],
    (variables?: AddProjectInviteMutationVariables) => fetcher<AddProjectInviteMutation, AddProjectInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectInviteDocument, variables)(),
    options,
  )
useAddProjectInviteMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddProjectInviteMutationVariables) => fetcher<AddProjectInviteMutation, AddProjectInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectInviteDocument, variables)
export const RemoveProjectInviteDocument = `
    mutation removeProjectInvite($id: ID!) {
  removeProjectInvite(id: $id)
}
    `
export const useRemoveProjectInviteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<RemoveProjectInviteMutation, TError, RemoveProjectInviteMutationVariables, TContext>,
) =>
  useMutation<RemoveProjectInviteMutation, TError, RemoveProjectInviteMutationVariables, TContext>(
    [ 'removeProjectInvite' ],
    (variables?: RemoveProjectInviteMutationVariables) => fetcher<RemoveProjectInviteMutation, RemoveProjectInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectInviteDocument, variables)(),
    options,
  )
useRemoveProjectInviteMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: RemoveProjectInviteMutationVariables) => fetcher<RemoveProjectInviteMutation, RemoveProjectInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectInviteDocument, variables)
export const RemoveProjectMemberDocument = `
    mutation removeProjectMember($id: ID!) {
  removeProjectMember(id: $id)
}
    `
export const useRemoveProjectMemberMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<RemoveProjectMemberMutation, TError, RemoveProjectMemberMutationVariables, TContext>,
) =>
  useMutation<RemoveProjectMemberMutation, TError, RemoveProjectMemberMutationVariables, TContext>(
    [ 'removeProjectMember' ],
    (variables?: RemoveProjectMemberMutationVariables) => fetcher<RemoveProjectMemberMutation, RemoveProjectMemberMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectMemberDocument, variables)(),
    options,
  )
useRemoveProjectMemberMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: RemoveProjectMemberMutationVariables) => fetcher<RemoveProjectMemberMutation, RemoveProjectMemberMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectMemberDocument, variables)
export const RemoveProjectSlotDocument = `
    mutation removeProjectSlot($id: ID!) {
  removeProjectSlot(id: $id)
}
    `
export const useRemoveProjectSlotMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<RemoveProjectSlotMutation, TError, RemoveProjectSlotMutationVariables, TContext>,
) =>
  useMutation<RemoveProjectSlotMutation, TError, RemoveProjectSlotMutationVariables, TContext>(
    [ 'removeProjectSlot' ],
    (variables?: RemoveProjectSlotMutationVariables) => fetcher<RemoveProjectSlotMutation, RemoveProjectSlotMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectSlotDocument, variables)(),
    options,
  )
useRemoveProjectSlotMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: RemoveProjectSlotMutationVariables) => fetcher<RemoveProjectSlotMutation, RemoveProjectSlotMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RemoveProjectSlotDocument, variables)
export const ListProjectNotesDocument = `
    query listProjectNotes($id: ID!) {
  listProjectNotes(projectId: $id) {
    id
    body
    createdAt
    updatedAt
    createdByIndividual {
      id
      pictureURL
      givenName
      familyName
    }
  }
}
    `
export const useListProjectNotesQuery = <
  TData = ListProjectNotesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ListProjectNotesQueryVariables,
  options?: UseQueryOptions<ListProjectNotesQuery, TError, TData>,
) =>
  useQuery<ListProjectNotesQuery, TError, TData>(
    [ 'listProjectNotes', variables ],
    fetcher<ListProjectNotesQuery, ListProjectNotesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListProjectNotesDocument, variables),
    options,
  )
useListProjectNotesQuery.document = ListProjectNotesDocument


useListProjectNotesQuery.getKey = (variables: ListProjectNotesQueryVariables) => [ 'listProjectNotes', variables ]


useListProjectNotesQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: ListProjectNotesQueryVariables) => fetcher<ListProjectNotesQuery, ListProjectNotesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListProjectNotesDocument, variables)
export const AddProjectNoteDocument = `
    mutation addProjectNote($parentId: ID!, $body: String!) {
  addProjectNote(projectId: $parentId, body: $body)
}
    `
export const useAddProjectNoteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddProjectNoteMutation, TError, AddProjectNoteMutationVariables, TContext>,
) =>
  useMutation<AddProjectNoteMutation, TError, AddProjectNoteMutationVariables, TContext>(
    [ 'addProjectNote' ],
    (variables?: AddProjectNoteMutationVariables) => fetcher<AddProjectNoteMutation, AddProjectNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectNoteDocument, variables)(),
    options,
  )
useAddProjectNoteMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddProjectNoteMutationVariables) => fetcher<AddProjectNoteMutation, AddProjectNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectNoteDocument, variables)
export const CreateNewProjectStatusDocument = `
    mutation createNewProjectStatus($label: String!, $color: String!, $progress: ProjectProgress!) {
  createNewProjectStatus(label: $label, color: $color, progress: $progress)
}
    `
export const useCreateNewProjectStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateNewProjectStatusMutation, TError, CreateNewProjectStatusMutationVariables, TContext>,
) =>
  useMutation<CreateNewProjectStatusMutation, TError, CreateNewProjectStatusMutationVariables, TContext>(
    [ 'createNewProjectStatus' ],
    (variables?: CreateNewProjectStatusMutationVariables) => fetcher<CreateNewProjectStatusMutation, CreateNewProjectStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateNewProjectStatusDocument, variables)(),
    options,
  )
useCreateNewProjectStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: CreateNewProjectStatusMutationVariables) => fetcher<CreateNewProjectStatusMutation, CreateNewProjectStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateNewProjectStatusDocument, variables)
export const UpdateProjectStatusDocument = `
    mutation updateProjectStatus($statusId: ID!, $label: String!, $color: String!, $progress: ProjectProgress!) {
  updateProjectStatus(
    statusId: $statusId
    label: $label
    color: $color
    progress: $progress
  )
}
    `
export const useUpdateProjectStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateProjectStatusMutation, TError, UpdateProjectStatusMutationVariables, TContext>,
) =>
  useMutation<UpdateProjectStatusMutation, TError, UpdateProjectStatusMutationVariables, TContext>(
    [ 'updateProjectStatus' ],
    (variables?: UpdateProjectStatusMutationVariables) => fetcher<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateProjectStatusDocument, variables)(),
    options,
  )
useUpdateProjectStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: UpdateProjectStatusMutationVariables) => fetcher<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateProjectStatusDocument, variables)
export const SelectProjectStatusDocument = `
    mutation selectProjectStatus($parentId: ID!, $statusId: ID!) {
  selectProjectStatus(parentId: $parentId, statusId: $statusId)
}
    `
export const useSelectProjectStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<SelectProjectStatusMutation, TError, SelectProjectStatusMutationVariables, TContext>,
) =>
  useMutation<SelectProjectStatusMutation, TError, SelectProjectStatusMutationVariables, TContext>(
    [ 'selectProjectStatus' ],
    (variables?: SelectProjectStatusMutationVariables) => fetcher<SelectProjectStatusMutation, SelectProjectStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SelectProjectStatusDocument, variables)(),
    options,
  )
useSelectProjectStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: SelectProjectStatusMutationVariables) => fetcher<SelectProjectStatusMutation, SelectProjectStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SelectProjectStatusDocument, variables)
export const ListAllProjectStatusDocument = `
    query listAllProjectStatus {
  listAllProjectStatus {
    id
    label
    progress
    color
  }
}
    `
export const useListAllProjectStatusQuery = <
  TData = ListAllProjectStatusQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllProjectStatusQueryVariables,
  options?: UseQueryOptions<ListAllProjectStatusQuery, TError, TData>,
) =>
  useQuery<ListAllProjectStatusQuery, TError, TData>(
    variables === undefined ? [ 'listAllProjectStatus' ] : [ 'listAllProjectStatus', variables ],
    fetcher<ListAllProjectStatusQuery, ListAllProjectStatusQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllProjectStatusDocument, variables),
    options,
  )
useListAllProjectStatusQuery.document = ListAllProjectStatusDocument


useListAllProjectStatusQuery.getKey = (variables?: ListAllProjectStatusQueryVariables) => variables === undefined ? [ 'listAllProjectStatus' ] : [ 'listAllProjectStatus', variables ]


useListAllProjectStatusQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListAllProjectStatusQueryVariables) => fetcher<ListAllProjectStatusQuery, ListAllProjectStatusQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllProjectStatusDocument, variables)
export const ListAllOpsWorkHistoryReferencesDocument = `
    query listAllOpsWorkHistoryReferences($filter: ListAllOpsWorkHistoryReferencesFilter) {
  listAllOpsWorkHistoryReferences(filter: $filter) {
    ...DashboardReferenceProfileFields
  }
}
    ${DashboardReferenceProfileFieldsFragmentDoc}`
export const useListAllOpsWorkHistoryReferencesQuery = <
  TData = ListAllOpsWorkHistoryReferencesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllOpsWorkHistoryReferencesQueryVariables,
  options?: UseQueryOptions<ListAllOpsWorkHistoryReferencesQuery, TError, TData>,
) =>
  useQuery<ListAllOpsWorkHistoryReferencesQuery, TError, TData>(
    variables === undefined ? [ 'listAllOpsWorkHistoryReferences' ] : [ 'listAllOpsWorkHistoryReferences', variables ],
    fetcher<ListAllOpsWorkHistoryReferencesQuery, ListAllOpsWorkHistoryReferencesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllOpsWorkHistoryReferencesDocument, variables),
    options,
  )
useListAllOpsWorkHistoryReferencesQuery.document = ListAllOpsWorkHistoryReferencesDocument


useListAllOpsWorkHistoryReferencesQuery.getKey = (variables?: ListAllOpsWorkHistoryReferencesQueryVariables) => variables === undefined ? [ 'listAllOpsWorkHistoryReferences' ] : [ 'listAllOpsWorkHistoryReferences', variables ]


useListAllOpsWorkHistoryReferencesQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListAllOpsWorkHistoryReferencesQueryVariables) => fetcher<ListAllOpsWorkHistoryReferencesQuery, ListAllOpsWorkHistoryReferencesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllOpsWorkHistoryReferencesDocument, variables)
export const ListAllContractorProfileWorkHistoryReferenceNotesDocument = `
    query listAllContractorProfileWorkHistoryReferenceNotes($id: ID!) {
  listAllContractorProfileWorkHistoryReferenceNotes(
    contractorProfileWorkHistoryReferenceId: $id
  ) {
    id
    body
    createdAt
    updatedAt
    createdByIndividual {
      id
      familyName
      givenName
      pictureURL
    }
  }
}
    `
export const useListAllContractorProfileWorkHistoryReferenceNotesQuery = <
  TData = ListAllContractorProfileWorkHistoryReferenceNotesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ListAllContractorProfileWorkHistoryReferenceNotesQueryVariables,
  options?: UseQueryOptions<ListAllContractorProfileWorkHistoryReferenceNotesQuery, TError, TData>,
) =>
  useQuery<ListAllContractorProfileWorkHistoryReferenceNotesQuery, TError, TData>(
    [ 'listAllContractorProfileWorkHistoryReferenceNotes', variables ],
    fetcher<ListAllContractorProfileWorkHistoryReferenceNotesQuery, ListAllContractorProfileWorkHistoryReferenceNotesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorProfileWorkHistoryReferenceNotesDocument, variables),
    options,
  )
useListAllContractorProfileWorkHistoryReferenceNotesQuery.document = ListAllContractorProfileWorkHistoryReferenceNotesDocument


useListAllContractorProfileWorkHistoryReferenceNotesQuery.getKey = (variables: ListAllContractorProfileWorkHistoryReferenceNotesQueryVariables) => [ 'listAllContractorProfileWorkHistoryReferenceNotes', variables ]


useListAllContractorProfileWorkHistoryReferenceNotesQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: ListAllContractorProfileWorkHistoryReferenceNotesQueryVariables) => fetcher<ListAllContractorProfileWorkHistoryReferenceNotesQuery, ListAllContractorProfileWorkHistoryReferenceNotesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorProfileWorkHistoryReferenceNotesDocument, variables)
export const AddContractorProfileWorkHistoryReferenceNoteDocument = `
    mutation addContractorProfileWorkHistoryReferenceNote($parentId: ID!, $body: String!) {
  addContractorProfileWorkHistoryReferenceNote(
    contractorProfileWorkHistoryReferenceId: $parentId
    body: $body
  )
}
    `
export const useAddContractorProfileWorkHistoryReferenceNoteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddContractorProfileWorkHistoryReferenceNoteMutation, TError, AddContractorProfileWorkHistoryReferenceNoteMutationVariables, TContext>,
) =>
  useMutation<AddContractorProfileWorkHistoryReferenceNoteMutation, TError, AddContractorProfileWorkHistoryReferenceNoteMutationVariables, TContext>(
    [ 'addContractorProfileWorkHistoryReferenceNote' ],
    (variables?: AddContractorProfileWorkHistoryReferenceNoteMutationVariables) => fetcher<AddContractorProfileWorkHistoryReferenceNoteMutation, AddContractorProfileWorkHistoryReferenceNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorProfileWorkHistoryReferenceNoteDocument, variables)(),
    options,
  )
useAddContractorProfileWorkHistoryReferenceNoteMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddContractorProfileWorkHistoryReferenceNoteMutationVariables) => fetcher<AddContractorProfileWorkHistoryReferenceNoteMutation, AddContractorProfileWorkHistoryReferenceNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorProfileWorkHistoryReferenceNoteDocument, variables)
export const ListAllContractorProfileWorkHistoryReferenceStatusesDocument = `
    query listAllContractorProfileWorkHistoryReferenceStatuses {
  listAllContractorProfileWorkHistoryReferenceStatuses {
    id
    label
    progress
    color
  }
}
    `
export const useListAllContractorProfileWorkHistoryReferenceStatusesQuery = <
  TData = ListAllContractorProfileWorkHistoryReferenceStatusesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllContractorProfileWorkHistoryReferenceStatusesQueryVariables,
  options?: UseQueryOptions<ListAllContractorProfileWorkHistoryReferenceStatusesQuery, TError, TData>,
) =>
  useQuery<ListAllContractorProfileWorkHistoryReferenceStatusesQuery, TError, TData>(
    variables === undefined ? [ 'listAllContractorProfileWorkHistoryReferenceStatuses' ] : [ 'listAllContractorProfileWorkHistoryReferenceStatuses', variables ],
    fetcher<ListAllContractorProfileWorkHistoryReferenceStatusesQuery, ListAllContractorProfileWorkHistoryReferenceStatusesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorProfileWorkHistoryReferenceStatusesDocument, variables),
    options,
  )
useListAllContractorProfileWorkHistoryReferenceStatusesQuery.document = ListAllContractorProfileWorkHistoryReferenceStatusesDocument


useListAllContractorProfileWorkHistoryReferenceStatusesQuery.getKey = (variables?: ListAllContractorProfileWorkHistoryReferenceStatusesQueryVariables) => variables === undefined ? [ 'listAllContractorProfileWorkHistoryReferenceStatuses' ] : [ 'listAllContractorProfileWorkHistoryReferenceStatuses', variables ]


useListAllContractorProfileWorkHistoryReferenceStatusesQuery.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables?: ListAllContractorProfileWorkHistoryReferenceStatusesQueryVariables) => fetcher<ListAllContractorProfileWorkHistoryReferenceStatusesQuery, ListAllContractorProfileWorkHistoryReferenceStatusesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllContractorProfileWorkHistoryReferenceStatusesDocument, variables)
export const SelectContractorProfileWorkHistoryReferenceStatusDocument = `
    mutation selectContractorProfileWorkHistoryReferenceStatus($parentId: ID!, $statusId: ID!) {
  selectContractorProfileWorkHistoryReferenceStatus(
    contractorProfileWorkHistoryReferenceId: $parentId
    contractorProfileWorkHistoryReferenceStatusId: $statusId
  )
}
    `
export const useSelectContractorProfileWorkHistoryReferenceStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<SelectContractorProfileWorkHistoryReferenceStatusMutation, TError, SelectContractorProfileWorkHistoryReferenceStatusMutationVariables, TContext>,
) =>
  useMutation<SelectContractorProfileWorkHistoryReferenceStatusMutation, TError, SelectContractorProfileWorkHistoryReferenceStatusMutationVariables, TContext>(
    [ 'selectContractorProfileWorkHistoryReferenceStatus' ],
    (variables?: SelectContractorProfileWorkHistoryReferenceStatusMutationVariables) => fetcher<SelectContractorProfileWorkHistoryReferenceStatusMutation, SelectContractorProfileWorkHistoryReferenceStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SelectContractorProfileWorkHistoryReferenceStatusDocument, variables)(),
    options,
  )
useSelectContractorProfileWorkHistoryReferenceStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: SelectContractorProfileWorkHistoryReferenceStatusMutationVariables) => fetcher<SelectContractorProfileWorkHistoryReferenceStatusMutation, SelectContractorProfileWorkHistoryReferenceStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SelectContractorProfileWorkHistoryReferenceStatusDocument, variables)
export const UpdateContractorProfileWorkHistoryReferenceStatusDocument = `
    mutation updateContractorProfileWorkHistoryReferenceStatus($statusId: ID!, $label: String!, $color: String!, $progress: ProgressionStatus!) {
  updateContractorProfileWorkHistoryReferenceStatus(
    contractorProfileWorkHistoryReferenceStatusId: $statusId
    label: $label
    color: $color
    progress: $progress
  )
}
    `
export const useUpdateContractorProfileWorkHistoryReferenceStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateContractorProfileWorkHistoryReferenceStatusMutation, TError, UpdateContractorProfileWorkHistoryReferenceStatusMutationVariables, TContext>,
) =>
  useMutation<UpdateContractorProfileWorkHistoryReferenceStatusMutation, TError, UpdateContractorProfileWorkHistoryReferenceStatusMutationVariables, TContext>(
    [ 'updateContractorProfileWorkHistoryReferenceStatus' ],
    (variables?: UpdateContractorProfileWorkHistoryReferenceStatusMutationVariables) => fetcher<UpdateContractorProfileWorkHistoryReferenceStatusMutation, UpdateContractorProfileWorkHistoryReferenceStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateContractorProfileWorkHistoryReferenceStatusDocument, variables)(),
    options,
  )
useUpdateContractorProfileWorkHistoryReferenceStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: UpdateContractorProfileWorkHistoryReferenceStatusMutationVariables) => fetcher<UpdateContractorProfileWorkHistoryReferenceStatusMutation, UpdateContractorProfileWorkHistoryReferenceStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateContractorProfileWorkHistoryReferenceStatusDocument, variables)
export const AddContractorProfileWorkHistoryReferenceStatusDocument = `
    mutation addContractorProfileWorkHistoryReferenceStatus($label: String!, $color: String!, $progress: ProgressionStatus!) {
  addContractorProfileWorkHistoryReferenceStatus(
    label: $label
    color: $color
    progress: $progress
  ) {
    id
  }
}
    `
export const useAddContractorProfileWorkHistoryReferenceStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddContractorProfileWorkHistoryReferenceStatusMutation, TError, AddContractorProfileWorkHistoryReferenceStatusMutationVariables, TContext>,
) =>
  useMutation<AddContractorProfileWorkHistoryReferenceStatusMutation, TError, AddContractorProfileWorkHistoryReferenceStatusMutationVariables, TContext>(
    [ 'addContractorProfileWorkHistoryReferenceStatus' ],
    (variables?: AddContractorProfileWorkHistoryReferenceStatusMutationVariables) => fetcher<AddContractorProfileWorkHistoryReferenceStatusMutation, AddContractorProfileWorkHistoryReferenceStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorProfileWorkHistoryReferenceStatusDocument, variables)(),
    options,
  )
useAddContractorProfileWorkHistoryReferenceStatusMutation.fetcher = (dataSource: { endpoint: string, fetchParams?: RequestInit }, variables: AddContractorProfileWorkHistoryReferenceStatusMutationVariables) => fetcher<AddContractorProfileWorkHistoryReferenceStatusMutation, AddContractorProfileWorkHistoryReferenceStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddContractorProfileWorkHistoryReferenceStatusDocument, variables)