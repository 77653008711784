import React, { useState, useRef, useImperativeHandle } from 'react'
import { IconMenuItem } from './IconMenuItem'
import Menu, { MenuProps } from '@mui/material/Menu'
import { MenuItemProps } from '@mui/material/MenuItem'
import { ChevronRight } from "@mui/icons-material"
import { ButtonMenuItem } from './ButtonMenuItem'

export interface NestedMenuItemProps extends Omit<MenuItemProps, 'button'> {
  parentMenuOpen: boolean,
  component?: React.ElementType,
  label?: string,
  rightIcon?: React.ReactNode,
  leftIcon?: React.ReactNode,
  children?: React.ReactNode,
  className?: string,
  tabIndex?: number,
  disabled?: boolean,
  ContainerProps?: React.HTMLAttributes<HTMLElement> &
  React.RefAttributes<HTMLElement | null>,
  MenuProps?: Partial<Omit<MenuProps, 'children'>>,
  button?: true | "isIconOnly" | undefined,
}

const NestedMenuItem = React.forwardRef<
HTMLLIElement | null,
NestedMenuItemProps
>((props, ref) => {
  const {
    parentMenuOpen,
    label,
    rightIcon = <ChevronRight />,
    leftIcon = null,
    children,
    className,
    tabIndex: tabIndexProp,
    ContainerProps: ContainerPropsProp = {},
    MenuProps,
    button,
    ...MenuItemProps
  } = props

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp

  const menuItemRef = useRef<HTMLLIElement>(null)
  useImperativeHandle(ref, () => menuItemRef.current as any)


  const containerRef = useRef<any>(null)
  useImperativeHandle(containerRefProp, () => containerRef.current)

  const menuContainerRef = useRef<any>(null)

  const [ isSubMenuOpen, setIsSubMenuOpen ] = useState(false)

  const handleClick = () => {
    if (button){
      setIsSubMenuOpen((prev) => !prev)
    }
  }

  const handleMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
    if (!button){
      setIsSubMenuOpen(true)

      if (ContainerProps.onMouseEnter) {
        ContainerProps.onMouseEnter(e)
      }
    }

  }
  const handleMouseLeave = (e: React.MouseEvent<HTMLElement>) => {

    if (ContainerProps.onMouseLeave) {
      ContainerProps.onMouseLeave(e)
    }

    setIsSubMenuOpen(false)

  }

  // Check if any immediate children are active
  const isSubmenuFocused = () => {
    const active = containerRef.current.ownerDocument.activeElement

    for (const child of menuContainerRef.current.children) {
      if (child === active) {
        return true
      }
    }
    return false
  }

  const handleFocus = (e: React.FocusEvent<HTMLElement>) => {
    if (e.target === containerRef.current) {
      setIsSubMenuOpen(true)
    }

    if (ContainerProps.onFocus) {
      ContainerProps.onFocus(e)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation()
  }

  const open = isSubMenuOpen && parentMenuOpen

  // Root element must have a `tabIndex` attribute for keyboard navigation
  let tabIndex
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1
  }

  return (
    <div
      {...ContainerProps}
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
    >
      {!button  && (
        <IconMenuItem
          MenuItemProps={MenuItemProps}
          className={className}
          ref={menuItemRef}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          label={label}
        />
      )}

      {button  && (
        <ButtonMenuItem
          MenuItemProps={MenuItemProps}
          className={className}
          ref={menuItemRef}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          label={label}
          isIconOnly={button === "isIconOnly"}
        />
      )}


      <Menu
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: 'none' }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        {...MenuProps}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </Menu>
    </div>
  )
})

NestedMenuItem.displayName = 'NestedMenuItem'
export { NestedMenuItem }
