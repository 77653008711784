type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };

export type EnvShape = {
  REACT_APP_AUTH0_CLIENT: string,
  REACT_APP_AUTH0_DOMAIN: string,
  REACT_APP_AUTH0_AUDIENCE: string,
  REACT_APP_SEGMENT_OPSFRONTEND: string,
  /** The URL of the deployed frontend e.g. `https://app.dan.dev.weaver.technology` */
  REACT_APP_APP_URL: string,
  /** The URL of the deployed dashboard (the current application) `https://dashboard.dan.dev.weaver.technology` */
  REACT_APP_DASHBOARD_URL: string,
  /** The URL of the deployed mecha core endpoint */
  REACT_APP_CORE_URL: string,
  REACT_APP_LAUNCHDARKLY_CLIENTSIDEID: string,
}

/** create an object that contains all the keys so we can iterate in requireEnv */
const envKeys: Exact<EnvShape> = {
  "REACT_APP_AUTH0_CLIENT": "",
  "REACT_APP_AUTH0_DOMAIN": "",
  "REACT_APP_AUTH0_AUDIENCE": "",
  "REACT_APP_SEGMENT_OPSFRONTEND": "",
  "REACT_APP_APP_URL": "",
  "REACT_APP_DASHBOARD_URL": "",
  "REACT_APP_CORE_URL": "",
  "REACT_APP_LAUNCHDARKLY_CLIENTSIDEID": "",
}

export function requireEnv() {
  const config: Record<string, string> = {}
  for (const key of Object.keys(envKeys)) {
    const envValue = process.env[key]
    if ( !envValue ) {
      throw new Error(`Missing ENV: ${key}`)
    }
    config[key] = envValue
  }

  // the loop above iterates over envShape keys, so the result will be the required env config
  return config as typeof envKeys
}
