import { useListAllContractorProfilesQuery, ListAllContractorProfilesFilter, ListAllContractorProfilesQuery, useListContractorProfileNotesQuery, useAddContractorProfileNoteMutation, useListAllContractorCompanyCheckStatusQuery, useUpdateContractorCompanyCheckStatusMutation, useSelectContractorCompanyCheckStatusMutation, useAddContractorCompanyCheckStatusMutation, useListAllContractorStatusQuery, useSelectContractorStatusMutation, useUpdateContractorStatusMutation, useAddContractorStatusMutation, ProgressionStatus, ContractorCompanyCheckProgress, ContractorProgress } from '../../graphql/generated'
import { useCoreApiSource } from '../../common/hooks/useCoreApiSource'
import { useSearchState } from '../../common/hooks/pages'
import React, { useMemo, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import { useDebounce } from 'use-debounce'
import CircularProgress from '@mui/material/CircularProgress'
import NotesWidget from '../../common/components/NotesWidget'
import StatusWidget from '../../common/components/Status/StatusWidget'
import { Box, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { getInitials } from '../../common/utils/text'
import { useAnalyticsEvent } from '../../common/hooks/analytics'
import { useNavigate } from 'react-router-dom'

type ContractorProfile = ListAllContractorProfilesQuery["listAllContractorProfiles"][number]
type WorkHistory = NonNullable<ContractorProfile["workHistory"]>[number]
type Reference = NonNullable<WorkHistory["references"]>[number]

const companyCheckProgressLabels = {
  [ContractorCompanyCheckProgress.NotChecked]: "Not Checked",
  [ContractorCompanyCheckProgress.Success]: "Success",
  [ContractorCompanyCheckProgress.Fail]: "Fail",
}

const contractorProgressLabels = {
  [ContractorProgress.NotChecked]: "Not Checked",
  [ContractorProgress.Success]: "Success",
  [ContractorProgress.Fail]: "Fail",
}

const ReferenceChip = styled(Tooltip)({
  border: "0.1rem solid black",
  cursor: "pointer",
  borderRadius: "0.3rem",
  boxSizing: "border-box",
  padding: "0.2rem",
  overflow: "hidden",
  textOverflow: "clip",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "1.5rem",
})


const ReferenceContainer = styled(Box)({
  gap: "0.2rem",
  display: "flex",
})

type JoinedReference = {
  reference: Reference,
  workHistory: WorkHistory,
  profile: ContractorProfile,
  id: string,
}

const SingleReferenceDisplay: React.FC<{data: JoinedReference}> = ({ data: { reference, id } }) => {
  const navigate = useNavigate()
  const name = reference.companyName ?
    reference.companyName :
    reference.familyName && reference.givenName ?
      `${reference.givenName} ${reference.familyName}` :
      null
  null

  return <ReferenceChip title={`${name || "Unknown Name"}: ${reference.status}`} onClick={() => navigate(`/references?idEquals=${encodeURIComponent(id)}`)}>
    <Typography sx={{ bgcolor: reference.status === ProgressionStatus.Completed ? "success.light" : "initial" }}>{name ? getInitials(name) : "?"}</Typography>
  </ReferenceChip>
}

const ReferenceDisplay: React.FC<{row: ContractorProfile}> = ({ row }) => {
  const joinedReferences = useMemo(() => {
    if (!row.workHistory) return []

    return row.workHistory.flatMap(workHistory => {
      return workHistory.references?.map(reference => ({
        reference,
        workHistory,
        profile: row,
        id: `${row.id}|${workHistory.id}|${reference.id}`,
      }))
    }).filter(x => !!x)
  }, [ row.workHistory ])

  return <ReferenceContainer>{joinedReferences?.map(x => x && <SingleReferenceDisplay key={x.id} data={x} />)}</ReferenceContainer>
}

const BuildersIndex: React.FC = () => {
  const gqlDatasource = useCoreApiSource()

  const [ searchState, mergeNext ] = useSearchState<ListAllContractorProfilesFilter>({})
  const [ debouncedSearch ] = useDebounce(searchState, 500)

  const useListAllQuery = useListAllContractorProfilesQuery

  const query = useListAllQuery(gqlDatasource, {
    filter: debouncedSearch,
  }, { refetchOnWindowFocus: false })

  const useListAllNotes = (id: string) => useListContractorProfileNotesQuery(gqlDatasource, { id }, {
    enabled: false,
    staleTime: 5000,
    select: ({ listContractorProfileNotes }) => listContractorProfileNotes,
  })
  const useAddNote = () => useAddContractorProfileNoteMutation(gqlDatasource)

  const useListAllCompanyCheckStatuses = () => useListAllContractorCompanyCheckStatusQuery(gqlDatasource, {}, {
    enabled: false,
    staleTime: 5000, // TODO: use something sensible
    select: ({ listAllContractorCompanyCheckStatus }) => listAllContractorCompanyCheckStatus,
  })

  const useSelectCompanyCheckStatus = () => useSelectContractorCompanyCheckStatusMutation(gqlDatasource)
  const useUpdateCompanyCheckStatus = () => useUpdateContractorCompanyCheckStatusMutation(gqlDatasource)
  const useAddCompanyCheckStatus = () => useAddContractorCompanyCheckStatusMutation(gqlDatasource)


  const useListAllStatuses = () => useListAllContractorStatusQuery(gqlDatasource, {}, {
    enabled: false,
    staleTime: 5000, // TODO: use something sensible
    select: ({ listAllContractorStatus }) => listAllContractorStatus,
  })

  const useSelectContractorStatus = () => useSelectContractorStatusMutation(gqlDatasource)
  const useUpdateContractorStatus = () => useUpdateContractorStatusMutation(gqlDatasource)
  const useAddContractorStatus = () => useAddContractorStatusMutation(gqlDatasource)

  const fireEvent_Ops_Contractor_Notes_Added = useAnalyticsEvent('Ops_Contractor_Notes_Added')
  const fireEvent_Ops_Contractor_Status_Set = useAnalyticsEvent('Ops_Contractor_Status_Set')
  const fireEvent_Ops_Contractor_Status_Create = useAnalyticsEvent('Ops_Contractor_Status_Create')
  const fireEvent_Ops_Contractor_Status_Update = useAnalyticsEvent('Ops_Contractor_Status_Update')


  const columns = useMemo<GridColDef<ContractorProfile>[]>(() => [
    { sortable: false, flex: 1, minWidth: 200, field: 'companyName', headerName: 'Name', renderCell: (params) => <div>{params.row.companyTradingAs || params.row.companyRegisteredName}</div> },
    { sortable: false, flex: 1, minWidth: 200, field: 'companyChecks', headerName: 'Company Checks', renderCell: (params) => <StatusWidget
      progressionLabels={companyCheckProgressLabels}
      parentId={params.row.id}
      statusId={params.row.companyCheckStatusId ?? undefined}
      useNewStatusMutation={useAddCompanyCheckStatus}
      useSelectStatusMutation={useSelectCompanyCheckStatus}
      useStatusQuery={useListAllCompanyCheckStatuses}
      useUpdateStatusMutation={useUpdateCompanyCheckStatus}
      onAfterSelectStatusMutation={async (oldStatus, newStatus) => {
        await fireEvent_Ops_Contractor_Status_Set({
          teamId: params.row.id,
          fieldName: params.colDef.field,
          oldStatus: oldStatus.label,
          oldWeaverStatus: oldStatus.progress,
          newStatus: newStatus.label,
          newWeaverStatus: newStatus.progress,
        })
      }}
      onAfterNewStatusMutation={async (newStatus) => {
        await fireEvent_Ops_Contractor_Status_Create({
          teamId: params.row.id,
          fieldName: params.colDef.field,
          oldStatus: undefined,
          oldWeaverStatus: undefined,
          newStatus: newStatus.label,
          newWeaverStatus: newStatus.progress,
        })
      }}
      onAfterUpdateStatusMutation={async (oldStatus, newStatus) => {
        await fireEvent_Ops_Contractor_Status_Update({
          teamId: params.row.id,
          fieldName: params.colDef.field,
          oldStatus: oldStatus.label,
          oldWeaverStatus: oldStatus.progress,
          newStatus: newStatus.label,
          newWeaverStatus: newStatus.progress,
        })
      }}
    /> },
    { sortable: false, flex: 1, minWidth: 200, field: 'referenceChecks', headerName: 'Reference Checks', renderCell: (params) => <ReferenceDisplay row={params.row} /> },
    { sortable: false, flex: 1, minWidth: 200, field: 'status', headerName: 'Status', renderCell: (params) => <StatusWidget
      progressionLabels={contractorProgressLabels}
      parentId={params.row.id}
      statusId={params.row.statusId ?? undefined}
      useNewStatusMutation={useAddContractorStatus}
      useSelectStatusMutation={useSelectContractorStatus}
      useStatusQuery={useListAllStatuses}
      useUpdateStatusMutation={useUpdateContractorStatus}
      onAfterSelectStatusMutation={async (oldStatus, newStatus) => {
        await fireEvent_Ops_Contractor_Status_Set({
          teamId: params.row.id,
          fieldName: params.colDef.field,
          oldStatus: oldStatus.label,
          oldWeaverStatus: oldStatus.progress,
          newStatus: newStatus.label,
          newWeaverStatus: newStatus.progress,
        })
      }}
      onAfterNewStatusMutation={async (newStatus) => {
        await fireEvent_Ops_Contractor_Status_Create({
          teamId: params.row.id,
          fieldName: params.colDef.field,
          oldStatus: undefined,
          oldWeaverStatus: undefined,
          newStatus: newStatus.label,
          newWeaverStatus: newStatus.progress,
        })
      }}
      onAfterUpdateStatusMutation={async (oldStatus, newStatus) => {
        await fireEvent_Ops_Contractor_Status_Update({
          teamId: params.row.id,
          fieldName: params.colDef.field,
          oldStatus: oldStatus.label,
          oldWeaverStatus: oldStatus.progress,
          newStatus: newStatus.label,
          newWeaverStatus: newStatus.progress,
        })
      }}
    /> },
    { sortable: false, flex: 1, minWidth: 200, field: 'notes', headerName: 'Notes', renderCell: (params) => <NotesWidget
      initialNoteCount={params.row._notesCount || 0}
      parentId={params.row.id}
      title={params.row.companyTradingAs || params.row.companyRegisteredName}
      useAddNoteMutation={useAddNote}
      useListNotesQuery={useListAllNotes}
      onAfterAddNoteMutation={async () => {
        await fireEvent_Ops_Contractor_Notes_Added({ teamId: params.row.id })
      }}
    /> },
  ], [])

  const data = query.data?.listAllContractorProfiles || []

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container rowSpacing={2}>
              <Grid item xs={6}>
                <TextField value={searchState.companyNameContains || ""} onChange={(e) => mergeNext({ companyNameContains: e.target.value })} label="Search" variant="outlined">
                </TextField>
                { query.isLoading && <CircularProgress /> }
              </Grid>
              <Grid item xs={6}>
                {!!query.error && (
                  <Alert severity="error">
                    <AlertTitle>Error loading Builders list</AlertTitle>
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  disableSelectionOnClick={true}
                  rowHeight={110}
                  autoHeight={true}
                  disableColumnMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BuildersIndex
