import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { useRequiredContext } from '../../../common/hooks/useRequiredContext'
import { useRemoveProjectSlotMutation } from '../../../graphql/generated'
import ClearIcon from "@mui/icons-material/Clear"
import { ManageSlotState } from '../types'
import { ProjectsIndexContext } from '../ProjectsIndexContext'

export const ManageSlot: React.FC<{modalState: ManageSlotState}> = ({ modalState }) => {
  const { handleModalSuccess } = useRequiredContext(ProjectsIndexContext)

  const { project, slot } = modalState

  const coreApi = useCoreApiSource()
  const removeProjectSlot = useRemoveProjectSlotMutation(coreApi)

  const [ isConfirmingClear, setConfirming ] = useState(false)

  const handleRemove = async () => {
    await removeProjectSlot.mutateAsync({ id: slot.id })
    setConfirming(false)
    handleModalSuccess(project.id)
  }

  const handleConfirmCancel = () => setConfirming(false)

  return <>
    <DialogTitle>Slot</DialogTitle>
    <DialogContent>
      {slot.requiredProjectRole} {slot.requiredTeamType}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setConfirming(true)}><ClearIcon /> Remove From Project</Button>
    </DialogActions>


    <Dialog
      open={isConfirmingClear}
      onClose={handleConfirmCancel}
    >
      <DialogTitle>Remove Slot</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you&apos;d like to remove this slot {slot.requiredProjectRole} {slot.requiredTeamType} from project {project.title}?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmCancel}>Cancel</Button>
        <Button onClick={handleRemove} autoFocus>Remove</Button>
      </DialogActions>
    </Dialog>
  </>
}
