import { useCallback, useRef, useState } from "react"
import createAuth0Client, { Auth0Client, User } from '@auth0/auth0-spa-js'
import { EnvShape, requireEnv } from "./config"
import React from "react"
import App from "./App"
import { QueryClient, QueryClientProvider } from "react-query"
import { GetMyIndividualQuery, TeamType, useGetMyIndividualQuery } from "./graphql/generated"
import Button from '@mui/material/Button'
import RefreshIcon from "@mui/icons-material/Refresh"
import { AnalyticsBrowser } from "@segment/analytics-next"
import { convertIsoDateToEpochQuietly } from "./common/utils/date"
import { withLDProvider  } from 'launchdarkly-react-client-sdk'
import { useEffectOnce } from "./common/hooks/useEffectOnce"
import { alwaysArray } from "./common/utils/data"

function requireRef<T>(x: React.MutableRefObject<T>): NonNullable<T> {
  if (!x.current) {
    throw new Error("missing guarded value")
  }

  return x.current
}

type BootstrapState = "initial" | "inprogress" | "ready" | "error" | "loggedout" | "preonboarding" | "unauthorized"

type AuthData = {
  auth0: Auth0Client,
  token: string,
  individual: NonNullable<GetMyIndividualQuery["getMyIndividual"]>,
}
export const AuthContext = React.createContext<AuthData| null>(null)
AuthContext.displayName = "AuthContext"
export const EnvContext = React.createContext<EnvShape| null>(null)
EnvContext.displayName = "EnvContext"
export const SegmentContext = React.createContext<AnalyticsBrowser| null>(null)
SegmentContext.displayName = "SegmentContext"

const CenterContainer: React.FC<{children?: React.ReactNode}> = ({ children }) => {
  return (
    <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      {children}
    </div>
  )
}


const Bootstrapper: React.FC = () => {
  const [ bootstrapState, setBootstrapState ] = useState<BootstrapState>("initial")

  const auth0Client = useRef<Auth0Client>()
  const auth0Token = useRef<string>()
  const auth0User = useRef<User>()
  const queryClient = useRef<QueryClient>()
  const processEnv = useRef<EnvShape>()
  const myIndividual = useRef<GetMyIndividualQuery["getMyIndividual"]>()
  const segment = useRef<AnalyticsBrowser>()
  const loginDone = useRef<(value: unknown) => unknown>()

  const handleSignin = useCallback(async () => {
    const client = requireRef(auth0Client)
    const done = requireRef(loginDone)

    await client.loginWithPopup()
    done(true)
  }, [ loginDone.current, auth0Client.current ])

  const bootstrap = async () => {
    try {
      if (bootstrapState !== "initial") return
      setBootstrapState("inprogress")

      const env = requireEnv()
      const qClient = new QueryClient()

      processEnv.current = env
      queryClient.current = qClient

      const client = await createAuth0Client({
        domain: env.REACT_APP_AUTH0_DOMAIN,
        client_id: env.REACT_APP_AUTH0_CLIENT,
        audience: env.REACT_APP_AUTH0_AUDIENCE,
      })

      auth0Client.current = client
      const isAuthenticated = await client.isAuthenticated()
      // if not authenticated, wait until logged in
      if (!isAuthenticated) {
        console.debug("[bootstrap] - not authenticated, waiting for user login")
        setBootstrapState("loggedout")
        await new Promise((resolve) => {
          loginDone.current = resolve
        })
      }

      console.debug("[bootstrap] getting token...")
      const [ token, user ] = await Promise.all([ client.getTokenSilently(), client.getUser() ])
      if (!user) throw new Error("Missing User")

      const dataSource = {
        endpoint: env.REACT_APP_CORE_URL,
        fetchParams: {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      }

      const myIndividualResponse = await qClient.fetchQuery(useGetMyIndividualQuery.getKey(), useGetMyIndividualQuery.fetcher(dataSource))

      const individual = myIndividualResponse.getMyIndividual
      if (!individual) throw new Error("Missing Individual")

      myIndividual.current = individual
      auth0Token.current = token
      auth0User.current = user

      const team = (individual.teamConnections ?? [])[0]?.team

      if (!team) {
        console.debug(`[bootstrap] email ${user.email} has not yet onboarded`)
        setBootstrapState("preonboarding")
        return
      }

      if (team.type !== TeamType.Weaver) {
        console.debug(`[bootstrap] email ${user.email} has team type ${team.type} which is not 'Weaver'`)
        setBootstrapState("unauthorized")
        return
      }

      const writeKey = env.REACT_APP_SEGMENT_OPSFRONTEND
      segment.current = AnalyticsBrowser.load({ writeKey })

      segment.current.identify(individual.id, {
        created_at: convertIsoDateToEpochQuietly(individual.createdAt), // Speced by MW-1063
        email: user.email,
        // NOTE: This is how we get it in the frontend within `segement/hooks.ts` but perhaps it isn't needed here
        // phone: auth.userData?.[WeaverIdTokenClaims.WeaverPhoneNumber],
        firstName: individual.givenName,
        lastName: individual.familyName,
        avatar: individual.pictureURL,
        loginMethod: `${user.sub}`.startsWith('google-oauth2|') // google-oauth2|0123456789abcdef
          ? 'google' //
          : `${user.sub}`.startsWith('auth0|') // auth0|0123456789abcdef
            ? 'auth0'
            : undefined,
        activeTeamId: team.id,
        activeTeamType: team.type,
        activeTeamName: team.name,
      })

      setBootstrapState("ready")
    } catch (e) {
      console.error(`Error during bootstrap ${e}`)
      setBootstrapState("error")
    }
  }

  useEffectOnce(() => {
    bootstrap()
  })

  console.log(bootstrapState)
  if ([ "initial", "inprogress" ].includes(bootstrapState)) {
    return <CenterContainer>
      <div>
        <p>Loading...</p>
      </div>
    </CenterContainer>

  } else if (bootstrapState === "loggedout") {
    return <CenterContainer>
      <div>
        <p>Please sign in to continue</p>
        <Button color="success" onClick={handleSignin}>Sign In</Button>
      </div>
    </CenterContainer>

  } else if (bootstrapState === "preonboarding") {
    return <CenterContainer>
      <div>
        <p>Before using this application you must onboard within the app.</p>
        <Button onClick={() => window.location.href = process.env.REACT_APP_APP_URL ?? ''} variant="outlined">Go to Onboarding</Button>
      </div>
    </CenterContainer>

  } else if (bootstrapState === "unauthorized") {
    const client = requireRef(auth0Client)
    const user = requireRef(auth0User)
    return <CenterContainer>
      <div>
        <p>You are not authorized to use this application.</p>
        <p>Signed In as {user.email}</p>
        <Button onClick={() => client.logout()} variant="outlined">Sign Out</Button>
      </div>
    </CenterContainer>

  } else if (bootstrapState === "error") {
    return <CenterContainer>
      <div>
        <p>Something went wrong, please refresh</p>
        <Button onClick={() => window.location.reload()}><RefreshIcon />refresh</Button>
      </div>
    </CenterContainer>

  } else if ( bootstrapState === "ready" ) {
    const authContext = {
      auth0: requireRef(auth0Client),
      token: requireRef(auth0Token),
      individual: requireRef(myIndividual),
    }
    const individual = authContext.individual
    const user = requireRef(auth0User)

    const ldUser = {
      key: individual.id,
      firstName: individual.givenName,
      lastName: individual.familyName,
      email: user.email,
      custom: {
        teamTypes: alwaysArray(individual.teamConnections).map(connection => connection.team.type),
      },
    }

    const AppWithLDProvider = withLDProvider({
      clientSideID: requireRef(processEnv).REACT_APP_LAUNCHDARKLY_CLIENTSIDEID,
      user: ldUser,
      reactOptions: {
        useCamelCaseFlagKeys: false,
      },
    })(() => {
      return <SegmentContext.Provider value={requireRef(segment)}>
        <EnvContext.Provider value={requireRef(processEnv)}>
          <QueryClientProvider client={requireRef(queryClient)}>
            <AuthContext.Provider value={authContext}>
              <App />
            </AuthContext.Provider>
          </QueryClientProvider>
        </EnvContext.Provider>
      </SegmentContext.Provider>
    },
    )

    return <AppWithLDProvider />
  } else {
    throw new Error(`Unknown AppState ${bootstrapState}`)
  }
}

export default Bootstrapper
